import React, { useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderBillingActions from 'bundles/orders/actions/orderBillingActions';
import { currencyFormat } from 'libs/support/number';
import styled from 'styled-components';
import VendorStatement from '../VendorStatement';

const Note = styled.span`
  color: ${props => props.theme.colors.greyChateau};
`;

const TAB = 'billing';

const Billing = ({
  activeTab,
  isFinding,
  isFindSuccess,
  findOrderBilling,
  orderBilling,
  orderId,
  translate,
}) => {
  if (activeTab !== TAB) return null;

  useEffect(() => {
    if (!isFindSuccess && !isFinding) {
      findOrderBilling(orderId);
    }
  }, [isFindSuccess, isFinding]);

  if (!orderBilling || orderBilling.size === 0) return null;

  const commissionKind = orderBilling.get('customCommission') ? 'Custom' : 'Standard';
  const translatedCommissionKind = translate(`orderBillingsLabels${commissionKind}`);

  return (
    <div id="order-billing">
      <div className="group-actions">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-title">
              <h6>
                { translate('orderBillingsTitlesVendorChargesAndCommissions') }
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="content-box">
        <table className="table table-sm-padding">
          <tbody>
            <tr>
              <td className="no-borders section-title" colSpan={3}>
                {translate('orderBillingsLabelsCommission')}
              </td>
            </tr>
            <tr>
              <td className="bottom-border-dotted" style={{ width: '80%', maxWidth: '80%' }}>
                {
                  translate(
                    'orderBillingsLabelsRateForOrder',
                    {
                      kind: translatedCommissionKind,
                      value: parseFloat(orderBilling.get('commissionPercentage')),
                    },
                  )
                }
              </td>
              <td className="bottom-border-dotted text-right" style={{ minWidth: '100px' }}>
                { currencyFormat(orderBilling.get('commissionAmount')) }
              </td>
            </tr>
            <tr>
              <td className="no-borders section-title" colSpan={3}>
                {translate('orderBillingsLabelsOtherCharges')}
              </td>
            </tr>
            <tr>
              <td className="bottom-border-dotted">
                {translate('orderBillingsLabelsOwnedToCaterspotByVendor')}
                {
                  orderBilling.get('ownedCaterspotNotes') &&
                    <Note className="ml-2 notes">
                      {translate('orderBillingsLabelsNotes')}: {orderBilling.get('ownedCaterspotNotes')}
                    </Note>
                }
              </td>
              <td className="bottom-border-dotted text-right">
                { currencyFormat(orderBilling.get('ownedCaterspotAmount')) }
              </td>
            </tr>
            <tr>
              <td className="bottom-border-dotted">
                {translate('orderBillingsLabelsOwnedToVendorByCaterspot')}
                {
                  orderBilling.get('ownedVendorNotes') &&
                    <Note className="ml-2 notes">
                      {translate('orderBillingsLabelsNotes')}: {orderBilling.get('ownedVendorNotes')}
                    </Note>
                }
              </td>
              <td className="bottom-border-dotted text-right">
                - { currencyFormat(orderBilling.get('ownedVendorAmount')) }
              </td>
            </tr>
            {
              orderBilling.get('displayTax') &&
                <tr>
                  <td className="bottom-border-dotted">
                    {translate('orderBillingsLabelsGst')}
                  </td>
                  <td className="bottom-border-dotted text-right">
                    { currencyFormat(orderBilling.get('taxAmount')) }
                  </td>
                </tr>
            }
            <tr>
              <td className="no-borders sub-total">
                {translate('orderBillingsLabelsTotalCommission')}
              </td>
              <td className="no-borders sub-total text-right">
                { currencyFormat(orderBilling.get('totalAmount')) }
              </td>
            </tr>
            <tr>
              <td className="no-borders grand-total p-t-20">
                {translate('orderBillingsLabelsTotalToBePaid')}
              </td>
              <td className="no-borders grand-total p-t-20 text-right">
                { currencyFormat(orderBilling.get('toBePaidAmount')) }
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <VendorStatement />
    </div>
  );
};

Billing.propTypes = {
  activeTab: string,
  isFinding: bool,
  isFindSuccess: bool,
  findOrderBilling: func.isRequired,
  orderBilling: object,
  orderId: string,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$orderStore,
  $$orderBillingStore,
}) => ({
  orderId: $$orderStore.getIn(['order', 'uuid']),
  isFinding: $$orderBillingStore.get('isFinding'),
  isFindSuccess: $$orderBillingStore.get('isFindSuccess'),
  orderBilling: $$orderBillingStore.get('orderBilling'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  findOrderBilling: orderBillingActions.findOrderBilling,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withI18n(Billing));
