import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/orderBillingConstants';

const apiUrl = (orderId) => `/orders/${orderId}/order_billings`;

const {
  FINDING_ORDER_BILLING,
  FIND_ORDER_BILLING_FAILURE,
  FIND_ORDER_BILLING_SUCCESS,
} = actionTypes;

export function findOrderBilling(orderId) {
  return api.get({
    types: [FINDING_ORDER_BILLING, FIND_ORDER_BILLING_SUCCESS, FIND_ORDER_BILLING_FAILURE],
    endpoint: apiUrl(orderId),
    params: null,
    id: false,
  });
}
