/* eslint max-len: 0 */

import React from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import moment from 'moment';
import { withI18n } from 'libs/support/i18n';
import Info from 'libs/components/Icons/Info';

const Container = styled.div`
  adding: 25px 60px;
  background: #FFFFFF;
  border: 4px solid #7D9E1D;
  box-shadow: 0px 2px 4px rgb(20 20 21 / 10%);
  border-radius: 3px;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column-reverse;

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    flex-direction: row;
  }
`;

const LeftContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1.5rem;

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    width: 78%;
  }

  > svg {
    flex-shrink: 0;
  }

  > div {
    font-size: 17px;
    line-height: 21px;
    padding-left: 1rem;

    > h6 {
      font-family: ${props => props.theme.fonts.geomanistbook} !important;
      font-weight: normal !important;
    }
  }
`;

const RightContainer = styled.div`
  width: 100%;
  background: rgba(125, 158, 29, 0.15);
  border-radius: 3px;
  padding: 1rem 1.5rem;
  text-align: center;
  color: ${props => props.theme.colors.palatinatePurple};
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    width: 22%;
  }

  > div {
    font-size: 17px;
    line-height: 21px;
    padding-left: 1rem;
    margin-top: auto;
    margin-bottom: auto;

    > p {
      font-size: 12px !important;
      text-transform: uppercase;
    }

    > h6 {
      color: ${props => props.theme.colors.palatinatePurple} !important;
      font-family: ${props => props.theme.fonts.geomanistbook} !important;
    }
  }
`;

const Header = ({
  deadlineAt,
  translate,
}) => (
  <Container className="container px-0">
    <LeftContainer>
      <Info />
      <div>
        <h6>{ translate('draftOrdersShowHeaderTitle') }</h6>
        <span>
          { translate('draftOrdersShowHeaderSubtext') }
        </span>
      </div>
    </LeftContainer>
    <RightContainer>
      <div>
        <p className="mb-0">
          { translate('draftOrdersShowHeaderTermsFinalOrder') }
        </p>
        <h6 className="mb-0">
          { /* deadlineAt doesnt use timezone, its always with assumption that the datetime is on GMT + 8 */ }
          { /* TODO: This should be handled by the API */ }
          { moment(deadlineAt).format('ddd D MMM YYYY h:mm A') }
        </h6>
      </div>
    </RightContainer>
  </Container>
);

Header.propTypes = {
  deadlineAt: string.isRequired,
  translate: func.isRequired,
};

export default withI18n(Header);
