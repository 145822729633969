/**
 * Small shim for preventDefault
 *
 * Ex.
 *   onClick={prevent(fn, 'test')}
 */
export function prevent(fn /* : any => any */, ...args /* : any */) {
  return (e /* : Event */) => {
    e.preventDefault();
    fn(...args);
  };
}
