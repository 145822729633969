/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { withAppContext } from 'libs/support/appContext';

import BaseComponent from 'libs/components/BaseComponent';
import { blankish } from 'libs/support/string';
import { CUTOFF_YEAR } from 'bundles/billings/constants/vendorStatementsConstants';
import { withI18n } from 'libs/support/i18n';

import OrderAside from './OrderAside/OrderAside';
import Tabs from './OrderTabs/OrderTabs';
import CustomerForm from './OrderForms/CustomerForm/CustomerForm';
import CatererForm from './OrderForms/CatererForm/CatererForm';
import OrderBasket from './OrderBasket/OrderBasket';
import OrderHistories from './OrderHistories';
import OrderHistoryModal from './OrderHistoryModal';
import HistoryWithDiff from './OrderHistories/HistoryWithDiff';
import OrderAlert from '../OrderAlert';
import Billing from './Billing';
import OrderDeclineModal from './OrderDeclineModal';

const TABS_V2 = ['basket', 'customer', 'vendor', 'order_history'];
const TABS_V3 = ['basket', 'customer', 'vendor', 'order_history', 'billing'];

class Order extends BaseComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    catererStore: PropTypes.object.isRequired,
    customMenuItemsStore: PropTypes.object.isRequired,
    flagIsEnabled: PropTypes.func.isRequired,
    orderableSettingStore: PropTypes.object.isRequired,
    orderStore: PropTypes.object.isRequired,
    orderVariationsStore: PropTypes.object.isRequired,
    userStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { showDeclineModal: false };
  }

  render() {
    const {
      flagIsEnabled,
      orderableSettingStore,
      orderHistoriesStore,
      orderStore,
      userStore,
      catererStore,
      orderVariationsStore,
      customMenuItemsStore,
      actions,
      translate,
    } = this.props;

    const order = orderStore.get('order');
    const deliverAtYear = (new Date(order.get('deliver_at'))).getFullYear();
    const isBillingEnabled = flagIsEnabled('order_billing_enabled') && deliverAtYear >= CUTOFF_YEAR;
    const orderId = order.get('uuid');
    const status = order.get('status');
    const user = userStore.get('user');
    const caterer = catererStore.get('caterer');
    const vendorKind = caterer.get('kind');
    const activeTab = orderStore.get('activeTab');
    const catererName = caterer.get('name');
    const orderDeliveryType = order.get('delivery_type');
    const lastAction = orderStore.get('lastAction');
    const errors = orderStore.get('errors');
    const requireConfirmation =
      ['changes_requested', 'new_changes_requested'].includes(status);
    const dataDiff = orderHistoriesStore.getIn(['orderHistories', 0, 'data_diff']);
    const userEmail = userStore.getIn(['user', 'email']);
    const labelEditorUrl = `/orders/${orderId}/meal_labels`;
    const showDecline = () => this.setState({ showDeclineModal: true });
    const hideDecline = () => this.setState({ showDeclineModal: false });

    return (
      <React.Fragment>
        {
          // Confirmation Alert
          !blankish(lastAction) &&
          <OrderAlert
            type={blankish(lastAction.match(/Declined|CancelledByCustomer/g)) ? 'success' : 'danger'}
            title={translate(`ordersShowOrderAlert${lastAction}Title`)}
            body={translate(`ordersShowOrderAlert${lastAction}Body`)}
          />
        }

        {
          // Errors Alert
          !blankish(errors) &&
          <OrderAlert
            type="danger"
            title={translate('ordersShowOrderAlertErrorsTitle')}
            body={errors}
          />
        }

        {
          // Confirm Order Alert
          window.fflags && window.fflags.order_alert_enabled === 'true' && status === 'newly_placed' &&
          <OrderAlert
            type="danger"
            title={translate('ordersShowOrderAlertNewlyPlacedTitle')}
            body={translate('ordersShowOrderAlertNewlyPlacedBody')}
            onConfirm={_ => actions.confirmOrder(orderId)}
            onDecline={showDecline}
          />
        }

        <OrderDeclineModal
          show={this.state.showDeclineModal}
          onHide={hideDecline}
          onSubmit={params => actions.declineOrder(orderId, params)}
        />

        {
          // Confirm Order Changes Alert
          window.fflags &&
          window.fflags.order_changes_alert_enabled === 'true' &&
          requireConfirmation &&
          <OrderAlert
            type="danger"
            title={translate('ordersShowOrderAlertChangesRequestedTitle')}
            body={translate('ordersShowOrderAlertChangesRequestedBody')}
            onConfirm={_ => actions.confirmOrderChanges(orderId)}
            onDecline={_ => actions.declineOrderChanges(orderId)}
          >
            {
              dataDiff &&
              <HistoryWithDiff
                diff={dataDiff}
              />
            }
          </OrderAlert>
        }

        {
          // Confirm Order Cancellation Alert
          status === 'cancellation_request' &&
          <OrderAlert
            type="danger"
            title={translate('ordersShowOrderAlertCancellationRequestTitle')}
            body={translate('ordersShowOrderAlertCancellationRequestBody')}
            onConfirm={_ => actions.confirmCancellationRequest(orderId)}
          />
        }

        <div className="container wrapper">
          <div className="row no-margin">
            <div className="col-md-12 main-content">
              <div className="row no-margin" style={{ display: 'table' }}>
                <div className="col-md-3 sidebar">
                  <OrderAside
                    name={`${user.get('first_name')} ${user.get('last_name')}`}
                    catererName={catererName}
                    orderDeliveryType={orderDeliveryType}
                    orderCreatedAt={order.get('created_at')}
                    orderDeliveryDate={order.get('deliver_at')}
                    orderEndDeliveryDate={order.get('end_deliver_at')}
                    orderPickupDate={order.get('pickup_at')}
                    orderDriverAssigned={order.get('driver_assigned')}
                    orderStatus={order.get('status')}
                    vertical={vendorKind}
                  />
                </div>

                <div className="col-md-9 main-content">
                  <div className="group-actions py-0">
                    <div className="row">
                      <div className="col-md-7 my-1">
                        <div className="heading-title">
                          <div className="d-inline-block pr-1">
                            <h6>
                              {translate('ordersShowTermsOrderId')}: {order.get('code')}
                            </h6>
                          </div>
                          <div className="d-inline-block pr-1">
                            <h6>
                              {translate('ordersShowTermsCountry')}: {caterer.get('country_full_name')}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 my-1 pull-right">
                        <a
                          className="btn btn-secondary btn-sm mr-1"
                          href={order.get('delivery_order_url')}
                          style={{ width: '150px' }}
                          target="blank"
                        >
                          {translate('ordersShowTermsDownloadDeliveryOrder')}
                        </a>
                        <a
                          className="btn btn-secondary btn-sm"
                          href={labelEditorUrl}
                          style={{ width: '150px' }}
                          target="blank"
                        >
                          {translate('ordersShowTermsPrintLabel')}
                        </a>
                      </div>
                    </div>
                  </div>

                  <Tabs
                    tabs={isBillingEnabled ? TABS_V3 : TABS_V2}
                    vendorKind={vendorKind}
                    activeTab={activeTab}
                    onChangeTab={actions.changeOrderTab}
                    orderDeliveryType={orderDeliveryType}
                  />

                  {
                    (orderDeliveryType === 'own' || vendorKind === 'activities') &&
                    <CustomerForm
                      activeTab={activeTab}
                      orderDeliveryType={orderDeliveryType}
                      firstName={order.get('vendor_resolved_contact_first_name')}
                      lastName={order.get('vendor_resolved_contact_last_name')}
                      mobile={order.get('vendor_resolved_contact_mobile')}
                      additionalInstructions={order.get('additional_instructions')}
                      address={order.get('address')}
                      vendorKind={vendorKind}
                      email={userEmail}
                    />
                  }


                  <CatererForm
                    activeTab={activeTab}
                    name={catererName}
                    outlet={caterer.get('outlet')}
                  />

                  <OrderBasket
                    consolidatedOrderVariations={orderVariationsStore.get('consolidatedOrderVariations')}
                    fflags={orderStore.get('fflags')}
                    isGroupOrder={order.get('group_order')}
                    activeTab={activeTab}
                    userName={user.get('first_name')}
                    voucherCode={order.get('voucher_code')}
                    surchargeName={order.get('surcharge_name')}
                    orderableSettingStore={orderableSettingStore}
                    orderActiveView={orderStore.get('orderActiveView')}
                    orderVariations={orderVariationsStore.get('orderVariations')}
                    customMenuItemsStore={customMenuItemsStore}
                    orderVersi={parseInt(order.get('versi'), 10)}
                    deliveryType={order.get('delivery_type')}
                    catererTaxType={order.get('tax_type')}
                    subtotalAmount={order.get('v_subtotal_amount')}
                    discountAmount={order.get('v_discount_amount')}
                    vendorRefund={order.get('v_vendor_refund_amount')}
                    caterspotRefund={order.get('v_caterspot_refund_amount')}
                    surchargeAmount={parseFloat(order.get('v_surcharge_amount')) || null}
                    deliveryAmount={order.get('v_delivery_amount')}
                    totalAmount={order.get('v_total_amount')}
                    taxAmount={order.get('v_tax_amount')}
                    vendorSmallOrderFee={order.get('v_vendor_small_order_fee')}
                    caterspotMarkupAmount={order.get('v_caterspot_markup_amount')}
                    hideUserName={order.get('hide_user_name')}
                  />

                  <OrderHistories
                    activeTab={activeTab}
                    orderHistories={orderHistoriesStore.get('orderHistories')}
                    onShow={actions.showOrderHistory}
                  />

                  <OrderHistoryModal
                    orderHistory={orderHistoriesStore.get('selectedOrderHistory')}
                    onHide={actions.hideOrderHistory}
                    order={order}
                  />

                  {
                    isBillingEnabled &&
                    <Billing
                      activeTab={activeTab}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withI18n(withAppContext(Order));
