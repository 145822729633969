import React, { Fragment } from 'react';
import { number, string } from 'prop-types';

import PaymentField from '../OrderVariation/PaymentField/PaymentField';
import DynamicField from '../OrderVariation/DynamicField/DynamicField';

/**
 * AmountRows
 */

const AmountRows = ({
  subtotalAmount,
  discountAmount,
  vendorRefund,
  caterspotRefund,
  surchargeAmount,
  deliveryAmount,
  totalAmount,
  taxAmount,
  voucherCode,
  surchargeName,
}) => (
  <Fragment>
    <PaymentField
      name="Subtotal"
      className="subtotal-details"
      value={subtotalAmount}
    />

    <DynamicField
      field="tax_amount"
      value={taxAmount}
    />


    <DynamicField
      field="discount_amount"
      value={discountAmount}
    />


    <DynamicField
      field="caterspot_refund"
      value={caterspotRefund}
    />


    <DynamicField
      field="vendor_refund"
      value={vendorRefund}
    />

    <PaymentField
      name="VoucherCode"
      value={voucherCode}
    />

    <DynamicField
      field="delivery_amount"
      value={deliveryAmount}
    />

    <PaymentField
      name="Surcharge"
      otherName={surchargeName}
      value={surchargeAmount}
    />

    <PaymentField
      isStrong
      name="Total"
      className="total"
      value={totalAmount}
    />

  </Fragment>
);

AmountRows.propTypes = {
  subtotalAmount: number,
  discountAmount: number,
  vendorRefund: number,
  caterspotRefund: number,
  surchargeAmount: number,
  deliveryAmount: number,
  totalAmount: number,
  taxAmount: number,
  voucherCode: string,
  surchargeName: string,
};

export default AmountRows;
