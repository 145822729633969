import React from 'react';
import { string, func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';

const Title = ({
  translate,
  orderActiveView,
}) => (
  <div className="order-items-header__title">
    <h6>
      {translate(`ordersShowFormsOrderViews${uppercamelcase(orderActiveView)}`)}
    </h6>
  </div>
);

Title.propTypes = {
  orderActiveView: string.isRequired,
  translate: func.isRequired,
};

export default withI18n(Title);
