export const FINDING_ORDER_BILLING = 'FINDING_ORDER_BILLING';
export const FIND_ORDER_BILLING_SUCCESS = 'FIND_ORDER_BILLING_SUCCESS';
export const FIND_ORDER_BILLING_FAILURE = 'FIND_ORDER_BILLING_FAILURE';

export const SAVING_ORDER_BILLING = 'SAVING_ORDER_BILLING';
export const SAVE_ORDER_BILLING_SUCCESS = 'SAVE_ORDER_BILLING_SUCCESS';
export const SAVE_ORDER_BILLING_FAILURE = 'SAVE_ORDER_BILLING_FAILURE';

export const SHOW_ORDER_BILLING_EDIT_MODAL = 'SHOW_ORDER_BILLING_EDIT_MODAL';
export const HIDE_ORDER_BILLING_EDIT_MODAL = 'HIDE_ORDER_BILLING_EDIT_MODAL';
