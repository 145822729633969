/* eslint max-len: 0, react/no-danger:0 */

import React from 'react';
import { func, string, node } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classnames from 'classnames';

import { defaultMessages } from 'libs/i18n/default';

const OrderAlert = ({
  body,
  children,
  intl,
  onConfirm,
  onDecline,
  title,
  type,
}) => {
  const { formatMessage } = intl;

  return (
    <div className="container px-0">
      <div
        className={classnames(
          'alert',
          { 'alert-danger': type === 'danger',
           'alert-success': type === 'success' },
        )}
      >
        <h5>{title}</h5>
        <p className="mb-0" dangerouslySetInnerHTML={{ __html: body }} />

        {
          children &&
            <div className="order-alert__children">{children}</div>
        }

        <div className="row">
          {
            onConfirm &&
              <div className="col-md-3 mt-1">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={onConfirm}
                >
                  {formatMessage(defaultMessages.ordersShowOrderAlertButtonsConfirm)}
                </button>
              </div>
          }
          {
            onDecline &&
              <div className="col-md-3 mt-1">
                <button
                  type="button"
                  className="btn btn-secondary btn-block"
                  onClick={onDecline}
                >
                  {formatMessage(defaultMessages.ordersShowOrderAlertButtonsDecline)}
                </button>
              </div>
          }
        </div>
      </div>
    </div>
  );
};

OrderAlert.propTypes = {
  body: string.isRequired,
  children: node,
  intl: intlShape.isRequired,
  onConfirm: func,
  onDecline: func,
  title: string.isRequired,
  type: string.isRequired, // 'success', 'danger'
};

export default injectIntl(OrderAlert);
