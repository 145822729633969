import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';

import BaseComponent from 'libs/components/BaseComponent';
import { notBlank } from 'libs/support/string';
import { withI18n } from 'libs/support/i18n';

class OrderBasketCustomMenuItem extends BaseComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    customMenuItem: PropTypes.object.isRequired,
    translate: PropTypes.func.isRequired,
  };

  render() {
    const { className, customMenuItem, translate } = this.props;
    const price = customMenuItem.get('price');
    const quantity = customMenuItem.get('quantity');
    const finalPrice = parseFloat(price);
    const finalQuantity = parseInt(quantity, 10);
    const totalPrice = Math.round((price * quantity) * 100) / 100;
    const description = customMenuItem.get('description');
    const specialInstructions = customMenuItem.get('special_instructions');

    return (
      <tr className={`${className} custom-menu-item`}>
        <td colSpan="2">
          {customMenuItem.get('name')}

          {
            notBlank(description) &&
            <div>{renderHTML(description)}</div>
          }

          {
            notBlank(specialInstructions) &&
              <Fragment>
                <br />
                <div>
                  {translate('ordersShowFormsCustomMenuItemsTermsAdditionalInstructions')}:
                  <br />
                  {renderHTML(specialInstructions)}
                </div>
              </Fragment>
          }
        </td>
        <td>{finalPrice}</td>
        <td>{finalQuantity}</td>
        <td>{totalPrice}</td>
      </tr>
    );
  }
}
export default withI18n(OrderBasketCustomMenuItem);
