/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable quotes */
import React, { memo, useState, Fragment } from 'react';
import { object, func } from 'prop-types';
import Pagination from 'libs/components/Pagination';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { useURLState } from 'bundles/common/hooks/useURLState';
import useDidMountEffect from 'bundles/common/hooks/useDidMountEffect';
import MobileHeader from 'bundles/v2/common/components/MobileHeader';
import debounce from 'lodash/debounce';
import { withI18n } from 'libs/support/i18n';
import { trackSearchBarUsed } from "packs/events";

import MobileOrderList from './MobileOrderList';
import DesktopOrderList from './DesktopOrderList';
import DesktopFilterSection from './DesktopFilterSection';
import MobileOrderHeader from './MobileOrderHeader';
import { useOrdersCount, useOrders } from '../queries/orders';
import MobileFilterModal from './MobileFilterModal';

const ACTION_REQUIRED_STATUSES = [
  'new',
  'change_requested',
  'new_with_changes',
  'cancellation_requested',
  're_confirmed',
];

const OrderList = ({
  outletsSelectedIds,
  translate,
}) => {
  const queryStringParams = new URLSearchParams(location.search);
  const defaultQuery = queryStringParams.get('query');
  const defaultOrderDateFrom = queryStringParams.get('order_date_from');
  const defaultOrderDateTo = queryStringParams.get('order_date_to');
  const defaultDeliveryDateFrom = queryStringParams.get('delivery_date_from');
  const defaultDeliveryDateTo = queryStringParams.get('delivery_date_to');
  const defaultFilter = queryStringParams.get('filter') || 'all';
  const defaultSort = queryStringParams.get('sort') || '';
  const defaultStatus = queryStringParams.get('status')?.split(',')?.filter(Boolean) ||
                  (defaultFilter === 'action_required' ? ACTION_REQUIRED_STATUSES : []) || [];

  const defaultOrderDate = defaultOrderDateFrom && defaultOrderDateTo ? [new Date(defaultOrderDateFrom), new Date(defaultOrderDateTo)] : [];
  const defaultDeliveryDate = defaultDeliveryDateFrom && defaultDeliveryDateTo ? [new Date(defaultDeliveryDateFrom), new Date(defaultDeliveryDateTo)] : [];

  const [orderDates, setOrderDates] = useState(defaultOrderDate);
  const [deliveryDates, setDeliveryDates] = useState(defaultDeliveryDate);
  const [query, setQuery] = useState(defaultQuery || '');
  const [filter, setFilter] = useState(defaultFilter);
  const [sort, setSort] = useState(defaultSort);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState(defaultStatus);

  const statusesCategories = [
    { label: translate('ordersFiltersLabelsNotYetActive'), children: [{ label: translate('ordersFiltersLabelsDraft'), value: 'draft', key: 'Draft' }] },
    {
      label: translate('ordersFiltersLabelsActive'),
      children: [
        { label: translate('ordersFiltersLabelsNew'), value: 'new', key: 'New' },
        { label: translate('ordersFiltersLabelsNewWithChanges'), value: 'new_with_changes', key: 'NewWithChanges' },
        { label: translate('ordersFiltersLabelsAccepted'), value: 'accepted', key: 'Accepted' },
        { label: translate('ordersFiltersLabelsChangesRequested'), value: 'change_requested', key: 'ChangeRequest' },
        { label: translate('ordersFiltersLabelsChangesDeclined'), value: 'changes_declined', key: 'ChangesDeclined' },
        { label: translate('ordersFiltersLabelsCancellationRequested'), value: 'cancellation_requested', key: 'CancellationRequested' },
      ],
    },
    {
      label: translate('ordersFiltersLabelsImminent'),
      children: [
        { label: translate('ordersFiltersLabelsToReconfirm'), value: 're_confirmed', key: 'ReConfirmed' },
        { label: translate('ordersFiltersLabelsConfirmed'), value: 'confirmed', key: 'Confirmed' },
      ],
    },
    {
      label: translate('ordersFiltersLabelsPast'),
      children: [
        { label: translate('ordersFiltersLabelsCompleted'), value: 'completed', key: 'Completed' },
        { label: translate('ordersFiltersLabelsDeclined'), value: 'declined', key: 'Declined' },
        { label: translate('ordersFiltersLabelsCancelled'), value: 'cancelled', key: 'Cancelled' },
      ],
    },
  ];

  const params = {
    outlet_ids: outletsSelectedIds.toArray(),
    page: queryStringParams.get('page') || 1,
    query,
    filter,
    sort,
    status,
  };

  useDidMountEffect(() => {
    params.page = 1;
  }, [outletsSelectedIds, query, filter, sort, status]);

  if (deliveryDates.length === 2) {
    params.delivery_date_from = moment(deliveryDates[0]).format('YYYY-MM-D');
    params.delivery_date_to = moment(deliveryDates[1]).format('YYYY-MM-D');
  }

  if (orderDates.length === 2) {
    params.order_date_from = moment(orderDates[0]).format('YYYY-MM-D');
    params.order_date_to = moment(orderDates[1]).format('YYYY-MM-D');
  }

  const {
    data: ordersResponse,
    isLoading: isFetching,
    hasNextPage,
    isFetchingNextPage, fetchNextPage,
  } = useOrders(params);

  const responses = ordersResponse?.pages[0] || {};
  const mobileOrders = ordersResponse?.pages || [];

  const orders = responses?.orders || [];
  const metadata = responses?.ordersMetadata || {};

  const { data: ordersCountResponse, isLoading: ordersCountIsLoading } = useOrdersCount(params);

  useURLState(params);

  const debouncedSearch = debounce(async (text) => {
    if (text.length === 0 || text.length >= 3) {
      trackSearchBarUsed();
      setQuery(text);
    }
  }, 500);

  const onChangeSearchInput = (e) => {
    debouncedSearch(e.target.value);
  };

  const isDraftOrder = filter === 'draft';

  const redirectToOrderDetails = (orderableId, klassName) => {
    window.location = `/v2/${klassName.toLowerCase()}s/${orderableId}`;
  };

  const dateDisplayText = (date) => {
    const startDate = moment(date[0]).format('MMM D');
    if (date[1] instanceof Date) {
      const endDate = moment(date[1]).format('MMM D, YYYY');
      return `${startDate} to ${endDate}`;
    }
    return startDate;
  };

  return (
    <Fragment>
      <div id="order-list-dashboard-mobile" className="order-lists">
        <MobileHeader>
          <MobileOrderHeader
            {
              ...{
                ordersCountResponse,
                ordersCountIsLoading,
                onChangeSearchInput,
                filter,
                setFilter,
                setOpenModal,
                setSort,
                sort,
                deliveryDates,
                orderDates,
                status,
              }
            }
          />
        </MobileHeader>
        <div className="mobile-order-list">
          <div className="orders-count-mobile">
            {orders.length} Orders
          </div>
          <MobileOrderList
            {
              ...{
                orders,
                isFetching,
                isDraftOrder,
                redirectToOrderDetails,
                mobileOrders,
                hasNextPage,
                isFetchingNextPage,
                fetchNextPage,
              }
            }
          />
        </div>
        <MobileFilterModal
          {
            ...{
              showModal: openModal,
              handleOnHideModal: () => setOpenModal(false),
              setSort,
              orderDates,
              setOrderDates,
              deliveryDates,
              setDeliveryDates,
              statusesCategories,
              setStatus,
              status,
              dateDisplayText,
              ordersCountResponse,
            }
          }
        />
      </div>
      <div id="order-list-desktop-container">
        <DesktopFilterSection
          {
            ...{
              orderDates,
              setOrderDates,
              deliveryDates,
              setDeliveryDates,
              query,
              setQuery,
              onChangeSearchInput,
              ordersCountResponse,
              ordersCountIsLoading,
              setStatus,
              status,
              statusesCategories,
              dateDisplayText,
            }
          }
        />
        <div className="orders-count-desktop">
          {orders.length} Orders
        </div>
        <div id="order-list-dashboard-desktop">
          <DesktopOrderList
            {
              ...{
                orders,
                isFetching,
                sort,
                setSort,
                isDraftOrder,
                redirectToOrderDetails,
              }
            }
          />
          <div className="pagination-container">
            {
              metadata && metadata.totalPages > 0 &&
                <Pagination
                  currentPage={metadata.page}
                  totalPages={metadata.totalPages}
                />
            }
          </div>
        </div>

      </div>
    </Fragment>
  );
};

OrderList.propTypes = {
  outletsSelectedIds: object,
  translate: func.isRequired,
};

export default memo(withRouter(withI18n(OrderList)));
