import React from 'react';
import { string, func } from 'prop-types';
import Title from './Title';
import Selection from './Selection';

const Header = ({
  orderActiveView,
  selectOrderView,
}) => (
  <div className="order-items-header">
    <Title {...{ orderActiveView }} />
    <Selection {...{ orderActiveView, selectOrderView }} />
  </div>
);

Header.propTypes = {
  orderActiveView: string.isRequired,
  selectOrderView: func.isRequired,
};

export default Header;
