import React, { Fragment } from 'react';
import { bool, object, string } from 'prop-types';
import classnames from 'classnames';
import OrderVariation from '../OrderVariation/OrderVariation';
import Options from '../OrderVariation/OptionsList/OptionsList';
import AdditionalInstructions from '../OrderVariation/AdditionalInstructions/AdditionalInstructions';
import OrderUser from '../OrderVariation/OrderUser/OrderUser';
import CustomMenuItem from '../CustomMenuItem/CustomMenuItem';
import OrderVariationDescription from '../OrderVariation/Description/Description';

const ODD = 'odd';
const EVEN = 'even';

const ItemsDetails = ({
  customMenuItems,
  isCafeOrdering,
  isGroupOrder,
  orderVariations,
  userName,
  hideUserName,
}) => (
  <Fragment>
    {
      orderVariations.map((orderVariation, index) => {
        const id = orderVariation.get('uuid');
        let className = ((index % 2) !== 0) ? EVEN : ODD;
        className = classnames(className, { '--highlight': orderVariation.get('new') });

        return [
          <OrderVariation
            key={`order-variation-${id}`}
            isCafeOrdering={isCafeOrdering}
            orderDateTime={orderVariation.get('confirmed_at')}
            vendorPriceBasedOnTaxType={orderVariation.get('vendor_price_based_on_tax_type')}
            vendorTotalPriceBasedOnTaxType={orderVariation.get('vendor_total_price_based_on_tax_type')}
            quantity={orderVariation.get('quantity')}
            className={className}
            nameString={orderVariation.get('name_string')}
            newItem={orderVariation.get('new')}
          />,
          <OrderVariationDescription
            key={`order-variation-description-${id}`}
            className={className}
            description={orderVariation.get('menu_item_description')}
            displayDescription={orderVariation.get('menu_item_display_description')}
            isCafeOrdering={isCafeOrdering}
          />,
          <Options
            key="choices"
            type="choice"
            indexLang="menuItemsTableHeadersOptions"
            isCafeOrdering={isCafeOrdering}
            className={className}
            choices={orderVariation.get('order_variation_option_choices')}
          />,
          <Options
            key="item-add-on-options"
            type="item-add-on-option"
            indexLang="menuItemsTableHeadersOptions"
            isCafeOrdering={isCafeOrdering}
            className={className}
            choices={orderVariation.get('order_variation_item_add_on_options')}
          />,
          <Options
            key="service-add-on-options"
            type="service-add-on-option"
            indexLang="menuItemsTableHeadersServiceAddOns"
            isCafeOrdering={isCafeOrdering}
            className={className}
            choices={orderVariation.get('order_variation_service_add_on_options')}
          />,
          <AdditionalInstructions
            key="additional-instructions"
            className={className}
            isCafeOrdering={isCafeOrdering}
            value={orderVariation.get('additional_instructions')}
          />,
          !hideUserName && (
            <OrderUser
              key="order_user"
              isCafeOrdering={isCafeOrdering}
              isGroupOrder={isGroupOrder}
              className={className}
              userName={userName}
              value={orderVariation.get('order_user_name')}
            />
          ),
        ];
      })
    }

    {
      customMenuItems.map((customMenuItem, index) => {
        const id = customMenuItem.get('uuid');
        const className = (((orderVariations.size + index) % 2) !== 0) ? EVEN : ODD;

        return (
          <CustomMenuItem
            key={`custom-menu-item-${id}`}
            className={className}
            customMenuItem={customMenuItem}
          />
        );
      })
    }
  </Fragment>
);

ItemsDetails.propTypes = {
  customMenuItems: object,
  isCafeOrdering: bool,
  isGroupOrder: bool,
  orderVariations: object,
  userName: string,
  hideUserName: bool.isRequired,
};

ItemsDetails.defaultProps = {
  isCafeOrdering: false,
};

export default ItemsDetails;
