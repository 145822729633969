/* eslint max-len: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import Intl from 'intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';

import BaseComponent from 'libs/components/BaseComponent';
import { translations } from 'libs/i18n/translations';

import * as orderActions from '../actions/orderActions';
import * as orderHistoryActions from '../actions/orderHistoryActions';

import Order from '../components/Order/Order';

global.Intl = Intl;

addLocaleData([...en, ...zh]);

function select(state) {
  return {
    orderableSettingStore: state.$$orderableSettingStore,
    orderStore: state.$$orderStore,
    userStore: state.$$userStore,
    catererStore: state.$$catererStore,
    orderVariationsStore: state.$$orderVariationsStore,
    customMenuItemsStore: state.$$customMenuItemsStore,
    orderHistoriesStore: state.$$orderHistoriesStore,
    localeStore: state.$$localeStore,
  };
}

class OrderContainer extends BaseComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    orderableSettingStore: PropTypes.object.isRequired,
    orderStore: PropTypes.object.isRequired,
    userStore: PropTypes.object.isRequired,
    catererStore: PropTypes.object.isRequired,
    orderVariationsStore: PropTypes.object.isRequired,
    customMenuItemsStore: PropTypes.object.isRequired,
    orderHistoriesStore: PropTypes.object.isRequired,
    localeStore: PropTypes.object.isRequired,
  };

  render() {
    const {
      catererStore,
      customMenuItemsStore,
      dispatch,
      localeStore,
      orderableSettingStore,
      orderHistoriesStore,
      orderStore,
      orderVariationsStore,
      userStore,
    } = this.props;
    const actions = bindActionCreators({
      ...orderActions,
      ...orderHistoryActions,
    }, dispatch);

    const locale = localeStore.get('locale');
    const messages = translations[locale];

    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <React.Fragment>
          <Order
            {...{
              actions,
              orderableSettingStore,
              orderStore,
              userStore,
              catererStore,
              orderVariationsStore,
              customMenuItemsStore,
              orderHistoriesStore,
            }}
          />
        </React.Fragment>
      </IntlProvider>
    );
  }
}

// Don't forget to actually use connect!
export default connect(select)(OrderContainer);
