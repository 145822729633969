import React from 'react';
import { func, object } from 'prop-types';
import map from 'lodash/map';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import Header from './Header';

const FIELDS = [
  'name',
  'street_address',
  'unit_building',
  'postcode',
  'city',
];

const OffSiteActivity = ({
  outlet,
  translate,
}) => (
  <div className="event-location">
    <Header />
    <div className="content-box">
      {
        map(FIELDS, field => (
          <div
            key={`input-${field}`}
            className="form-group row"
          >
            <label className="col-sm-3 form-control-label">
              {translate(`ordersShowFormsOutletFields${uppercamelcase(field)}`)}
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name={`outlet[${field}]`}
                className="form-control"
                defaultValue={outlet.get(field)}
              />
            </div>
          </div>
        ))
      }
    </div>
  </div>
);

OffSiteActivity.propTypes = {
  outlet: object,
  translate: func.isRequired,
};

export default withI18n(OffSiteActivity);
