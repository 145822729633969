import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { defaultMessages } from 'libs/i18n/default';
import OptionAdditionalInfo from './OptionAdditionalInfo';

const CHOICE = 'choice';
const SERVICE = 'service-add-on-option';

function renderBreak(index) {
  if (index === 0) return <br />;
  return null;
}

class OrderBasketOrderVariationOptionChoicesList extends BaseComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    isCafeOrdering: PropTypes.bool.isRequired,
    indexLang: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    choices: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const {
      type, isCafeOrdering, indexLang, className, choices, intl,
    } = this.props;
    const { formatMessage } = intl;
    const choicesSize = choices.size;
    const rows = choices.map((choice, index) => {
      const tdClassName = classnames('no-borders', { 'p-b-15': index + 1 === choicesSize });
      const quantity = parseInt(choice.get('quantity'), 10);
      const vendorPriceBasedOnTaxType = choice.get('vendor_price_based_on_tax_type');
      const vendorTotalPriceBasedOnTaxType = choice.get('vendor_total_price_based_on_tax_type');

      return (
        <tr
          key={`choice-${choice.get('order_variation_id')}-${choice.get('uuid')}`}
          className={className}
        >
          { isCafeOrdering && <td className={tdClassName} /> }
          <td
            className={tdClassName}
            colSpan="2"
          >
            {
              (index === 0) &&
              <div>
                {formatMessage(defaultMessages[indexLang])}:
                <br />
              </div>
            }

            {choice.get('name')}

            <OptionAdditionalInfo {...{ choice }} />
          </td>

          <td className={tdClassName}>
            {
              (type !== CHOICE) &&
              <div>
                {renderBreak(index)}
                {vendorTotalPriceBasedOnTaxType > 0 && vendorPriceBasedOnTaxType}
              </div>
            }
          </td>

          <td className={tdClassName}>
            {renderBreak(index)}

            {
              (choice.get('enable_quantity_option') && (type === SERVICE || quantity > 1)) &&
              quantity
            }
          </td>

          <td className={tdClassName} colSpan="2">
            {renderBreak(index)}

            {
              (type !== CHOICE) &&
                vendorTotalPriceBasedOnTaxType > 0 &&
                vendorTotalPriceBasedOnTaxType
            }
          </td>
        </tr>
      );
    });

    return rows;
  }
}

export default injectIntl(OrderBasketOrderVariationOptionChoicesList);
