import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import ModalFooter from 'libs/components/ModalFooterComponent';
import {
  FieldGroup,
  InputField,
} from 'libs/components/Forms';
import { Spacer } from 'libs/support/sharedComponents';
import { SubmitButton } from 'bundles/common/components/Buttons';
import { SecondaryButton } from 'bundles/common/components/Buttons';

const OrderAcceptModal = ({ show, onHide, onSubmit, driver, translate }) => {
  const formId = 'accept-order-form';
  const SINGAPORE_NUMBER_PATTERN = '[8-9]{1}[0-9]{7}';
  const initialDriverName = driver ? driver.name : '';
  const initialDriverPhoneNumber = driver ? driver.phoneNumber : '';
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById(formId);
    const params = new FormData(form);

    onSubmit(params);
    onHide();
  };

  const handleSubmitWithoutDriver = () => {
    const params = new FormData();

    onSubmit(params);
    onHide();
  };

  const handleSaveButtonDisabled = () => {
    const form = document.getElementById(formId);

    setSubmitButtonDisabled(!form.checkValidity());
  };

  useEffect(() => handleSaveButtonDisabled(), []);

  return (
    <Modal
      show={show}
      className="modal-lg modal-decline-form"
      onHide={onHide}
    >
      <ModalHeader enableClose>
        <h5 className="modal-title">{translate('ordersShowDetailsAcceptModalHeader')}</h5>
      </ModalHeader>

      <ModalBody>
        <div className="modal-body-content container-fluid">
          <p>{translate('ordersShowDetailsAcceptModalBody')}</p>
          <Spacer />
          <form id={formId}>
            <FieldGroup
              label={translate('ordersShowDetailsAcceptModalDriverName')}
            >
              <InputField
                placeholder={translate('ordersShowDetailsAcceptModalDriverNamePlaceholder')}
                name="driver_name"
                onChange={handleSaveButtonDisabled}
                value={initialDriverName}
                isRequired
              />
            </FieldGroup>

            <FieldGroup
              label={translate('ordersShowDetailsAcceptModalDriverPhoneNumber')}
            >
              <InputField
                placeholder={translate('ordersShowDetailsAcceptModalDriverPhoneNumberPlaceholder')}
                name="driver_phone_number"
                onChange={handleSaveButtonDisabled}
                value={initialDriverPhoneNumber}
                pattern={SINGAPORE_NUMBER_PATTERN}
                isRequired
              />
            </FieldGroup>
          </form>
        </div>
      </ModalBody>

      <ModalFooter>
        <SecondaryButton
          onClick={handleSubmitWithoutDriver}
          name={translate('ordersShowDetailsAcceptModalSkipButton')}
          form={formId}
          className="mr-1"
        />
        <SubmitButton
          onClick={handleSubmit}
          name={translate('ordersShowDetailsAcceptModalSaveButton')}
          form={formId}
          disabled={submitButtonDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};

OrderAcceptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  driver: PropTypes.object,
  translate: PropTypes.func.isRequired,
};

export default OrderAcceptModal;
