import React from 'react';
import { func, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';

const sumQuantity = (list) => (
  list.toList().reduce((total, item) => total + item.get('quantity'), 0)
);

const getTotal = ({
  customMenuItems,
  orderVariations,
}) => (
  sumQuantity(orderVariations) + sumQuantity(customMenuItems)
);

const TotalQuantity = ({
  customMenuItems,
  orderVariations,
  translate,
}) => (
  <span className="total-quantity">
    <span className="total-quantity__label">
      {translate('ordersShowFormsOrderTotalQuantity')}:
    </span>
    <span>
      {getTotal({ orderVariations, customMenuItems })}
    </span>
  </span>
);

TotalQuantity.propTypes = {
  customMenuItems: object,
  orderVariations: object,
  translate: func,
};

export default withI18n(TotalQuantity);
