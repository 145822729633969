/* eslint camelcase: 0, max-len: 0 */

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import humps from 'lodash-humps';
import Immutable from 'immutable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import {
  ORDER_DETAILS_VIEW,
  ORDER_SUMMARY_VIEW,
} from 'bundles/orders/constants/orderConstants';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';
import { notBlank } from 'libs/support/string';
import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    caterer,
    custom_menu_items,
    consolidated_order_variations,
    fflags,
    locale,
    order,
    orderable_setting,
    order_histories,
    order_variations,
    user,
  } = props;
  const {
    $$orderState, $$userState, $$catererState, $$orderVariationsState, $$customMenuItemsState, $$orderHistoriesState, $$localeState,
  } = initialStates;

  const initialState = {
    $$orderStore: $$orderState.merge({
      order,
      orderActiveView: (notBlank(order.cafe_ordering) && order.cafe_ordering) ? ORDER_DETAILS_VIEW : ORDER_SUMMARY_VIEW,
      fflags: humps(fflags),
    }),

    $$userStore: $$userState.merge({
      user,
    }),

    $$catererStore: $$catererState.merge({
      caterer,
    }),

    $$orderVariationsStore: $$orderVariationsState.merge({
      consolidatedOrderVariations: consolidated_order_variations,
      orderVariations: order_variations,
    }),

    $$customMenuItemsStore: $$customMenuItemsState.merge({
      customMenuItems: custom_menu_items,
    }),

    $$orderHistoriesStore: $$orderHistoriesState.merge({
      orderHistories: order_histories,
    }),

    $$localeStore: $$localeState.merge({
      locale,
    }),

    $$orderableSettingStore: Immutable.fromJS({ ...humps(orderable_setting) }),
  };

  const reducer = combineReducers(reducers);
  const composedStore = composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
