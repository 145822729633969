import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withI18n } from 'libs/support/i18n';
import OptionAdditionalInfo from './OptionAdditionalInfo';

const SERVICE = 'service-add-on-option';

const ConsolidatedOptionsList = ({
  type,
  indexLang,
  className,
  choices,
  translate,
}) => {
  const choicesSize = choices.size;
  const rows = choices.map((choice, index) => {
    const tdClassName = classnames('no-borders', { 'p-b-15': index + 1 === choicesSize });
    const quantity = parseInt(choice.get('quantity'), 10);

    return (
      <tr
        key={`choice-${choice.get('order_variation_id')}-${choice.get('uuid')}`}
        className={className}
      >
        <td />
        <td
          className={tdClassName}
        >
          {
            (index === 0) &&
              <div>
                {translate(indexLang)}:
                <br />
              </div>
          }

          {
            (choice.get('enable_quantity_option') && (type === SERVICE || quantity > 1)) &&
              `${quantity}x `
          }

          {choice.get('name')}

          <OptionAdditionalInfo {...{ choice }} />
        </td>
      </tr>
    );
  });

  return rows;
};

ConsolidatedOptionsList.propTypes = {
  type: PropTypes.string.isRequired,
  indexLang: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  choices: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withI18n(ConsolidatedOptionsList);
