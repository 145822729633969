import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AlertModalContainer from 'bundles/common/containers/AlertModalContainer';
import ImageModalContainer from 'bundles/common/containers/ImageModalContainer';
import ItemsContainer from '../Items/ItemsContainer';
import EditContainer from '../Item/EditContainer';
import NewContainer from '../Item/NewContainer';
import CategoriesContainer from '../Categories/CategoriesContainer';
import NewCategoryContainer from '../Category/NewCategoryContainer';
import EditCategoryContainer from '../Category/EditCategoryContainer';
import SettingsContainer from '../Settings/SettingsContainer';
import VendorRouterContainer from './VendorRouterContainer';


const Vendor = () => (
  <Router>
    <VendorRouterContainer>
      <Switch>
        <Route path="/menus/items/:id/edit" component={EditContainer} />
        <Route path="/menus/items/new" component={NewContainer} />
        <Route path="/menus/items" component={ItemsContainer} />
        <Route path="/menus/categories/new" component={NewCategoryContainer} />
        <Route path="/menus/categories/:id/edit" component={EditCategoryContainer} />
        <Route path="/menus/categories" component={CategoriesContainer} />
        <Route path="/menus/settings" component={SettingsContainer} />
        <Route path="/menus" component={ItemsContainer} />
        <Route path="/v2/menus" component={ItemsContainer} />
      </Switch>
      <AlertModalContainer />
      <ImageModalContainer />
    </VendorRouterContainer>
  </Router>
);

export default Vendor;
