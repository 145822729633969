/* eslint react/no-array-index-key: 0 */

import React, { Fragment } from 'react';
import {
  bool,
  func,
  number,
  object,
  string,
} from 'prop-types';
import { withI18n } from 'libs/support/i18n';

const OrderBasketConsolidatedOrderVariation = ({
  className,
  labels,
  isGroupOrder,
  nameString,
  quantity,
  translate,
  userName,
  rowCount,
  showTotal,
  totalPrice,
  hideUserName,
}) => (
  <tr className={`${className} consolidated-order-variation`}>
    <td className="strong">{quantity}x</td>
    <td>{nameString}</td>
    <td colSpan={showTotal ? '1' : '3'} rowSpan={rowCount} className="labels-block">
      {
        isGroupOrder &&
        <Fragment>
          {translate('ordersShowFormsOrderLabel')}:
          <ul>
            {
              labels.map((label, index) => (
                <li key={`label-${label.get('user_name')}-${index}`}>
                  {label.get('quantity')}x {hideUserName ? '' : label.get('user_name') || userName}
                </li>
              ))
            }
          </ul>
        </Fragment>
      }
    </td>

    {
      showTotal &&
      <td colSpan="2" rowSpan={rowCount}>
        {totalPrice}
      </td>
    }
  </tr>
);

OrderBasketConsolidatedOrderVariation.propTypes = {
  className: string.isRequired,
  labels: object.isRequired,
  isGroupOrder: bool,
  nameString: string.isRequired,
  quantity: number.isRequired,
  rowCount: number.isRequired,
  translate: func.isRequired,
  userName: string.isRequired,
  showTotal: bool.isRequired,
  totalPrice: string.isRequired,
  hideUserName: bool.isRequired,
};

export default withI18n(OrderBasketConsolidatedOrderVariation);
