import React from 'react';
import { func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';

const Header = ({
  translate,
}) => (
  <div className="group-actions">
    <div className="row">
      <div className="col-md-12">
        <div className="heading-title">
          <h6>
            {translate('ordersShowFormsCustomerEventLocation')}
          </h6>
        </div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  translate: func.isRequired,
};

export default withI18n(Header);
