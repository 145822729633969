/* eslint max-len: 0 */

import React from 'react';
import { bool, func, number, object, string } from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import classnames from 'classnames';

import { withI18n } from 'libs/support/i18n';
import BaseComponent from 'libs/components/BaseComponent';

import OrderItemsHeaderContainer from 'bundles/orders/containers/OrderItemsHeaderContainer';
import { ORDER_DETAILS_VIEW } from 'bundles/orders/constants/orderConstants';

import CutleryMessage from './CutleryMessage';
import ItemsDetails from './Views/ItemsDetails';
import ItemsSummary from './Views/ItemsSummary';
import AmountRows from './AmountRows';

const TAB = 'basket';
const DETAILS_HEADERS = [['title', '60%', 2], ['price', '10%', 1], ['qty', '10%', 1], ['total', '20%', 1]];
const SUMMARY_HEADERS = [['qty', '5%', 1], ['title', '50%', 1], ['empty', '45%', 3]];
const SUMMARY_HEADERS_V4 = [['qty', '5%', 1], ['title', '50%', 1], ['empty', '30%', 1], ['total', '15%', 2]];
const CAFE_ORDERING_HEADERS = [['order_time', '15%', 1], ['title', '50%', 2], ['price', '10%', 1], ['qty', '10%', 1], ['total', '15%', 1]];

class OrderBasket extends BaseComponent {
  static propTypes = {
    consolidatedOrderVariations: object.isRequired,
    fflags: object.isRequired,
    isCafeOrdering: bool,
    isGroupOrder: bool,
    activeTab: string.isRequired,
    userName: string.isRequired,
    voucherCode: string,
    surchargeName: string,
    orderableSettingStore: object.isRequired,
    orderActiveView: string.isRequired,
    orderVariations: object.isRequired,
    customMenuItemsStore: object.isRequired,
    orderVersi: number.isRequired,
    deliveryType: string.isRequired,
    catererTaxType: string.isRequired,
    subtotalAmount: number,
    discountAmount: number,
    vendorRefund: number,
    caterspotRefund: number,
    surchargeAmount: number,
    deliveryAmount: number,
    totalAmount: number,
    taxAmount: number,
    vendorSmallOrderFee: number,
    errorMsg: string,
    translate: func.isRequired,
    hideUserName: bool.isRequired,
  };

  static defaultProps = {
    isCafeOrdering: false,
  }

  getHeaders = () => {
    const {
      isCafeOrdering,
      orderActiveView,
      orderVersi,
    } = this.props;

    if (isCafeOrdering && orderActiveView === ORDER_DETAILS_VIEW) { return CAFE_ORDERING_HEADERS; }

    const summaryHeaders = orderVersi === 4 ? SUMMARY_HEADERS_V4 : SUMMARY_HEADERS;
    return orderActiveView === ORDER_DETAILS_VIEW ? DETAILS_HEADERS : summaryHeaders;
  }

  render() {
    const {
      caterspotMarkupAmount,
      subtotalAmount,
      discountAmount,
      vendorRefund,
      caterspotRefund,
      surchargeAmount,
      deliveryAmount,
      totalAmount,
      taxAmount,
      vendorSmallOrderFee,
      consolidatedOrderVariations,
      fflags,
      isCafeOrdering,
      isGroupOrder,
      activeTab,
      userName,
      voucherCode,
      surchargeName,
      orderableSettingStore,
      orderActiveView,
      orderVariations,
      customMenuItemsStore,
      orderVersi,
      deliveryType,
      catererTaxType,
      errorMsg,
      translate,
      hideUserName,
    } = this.props;
    const customMenuItems = customMenuItemsStore.get('customMenuItems');
    const headers = this.getHeaders();

    if (activeTab !== TAB) return null;

    return (
      <div className="content-box">
        <OrderItemsHeaderContainer />

        {
          errorMsg &&
            <div className="mt-2 alert alert-danger order-basket-alert" role="alert">
              { errorMsg }
            </div>
        }

        <table className={classnames('table table-basket', { 'cafe-ordering': isCafeOrdering })}>
          <thead>
            <tr>
              {
                headers.map(data => (
                  <th
                    key={`header-${data[0]}`}
                    width={data[1]}
                    colSpan={data[2]}
                  >
                    {translate(`ordersShowFormsOrderVariationsTable${uppercamelcase(data[0])}`)}
                  </th>
                ))
              }
            </tr>
          </thead>

          <tbody>
            {
              orderActiveView === 'order_details' &&
              <ItemsDetails
                {
                  ...{
                    customMenuItems,
                    isCafeOrdering,
                    isGroupOrder,
                    orderVariations,
                    userName,
                    hideUserName,
                  }
                }
              />
            }

            {
              orderActiveView !== 'order_details' &&
              <ItemsSummary
                {
                  ...{
                    customMenuItems,
                    consolidatedOrderVariations,
                    isGroupOrder,
                    userName,
                    showTotal: orderVersi === 4,
                    hideUserName,
                  }
                }
              />
            }

            {
              fflags.get('cutleryEnabled') &&
              <tr>
                <td colSpan={headers.length}>
                  <CutleryMessage
                    {
                      ...{
                        orderableSettingStore,
                      }
                    }
                  />
                </td>
              </tr>
            }

            <AmountRows
              caterspotMarkupAmount={caterspotMarkupAmount?.toFixed(2)}
              versi={orderVersi}
              discountAmount={discountAmount?.toFixed(2)}
              caterspotRefund={caterspotRefund?.toFixed(2)}
              voucherCode={voucherCode}
              subtotalAmount={subtotalAmount?.toFixed(2)}
              vendorRefund={vendorRefund?.toFixed(2)}
              surchargeAmount={surchargeAmount?.toFixed(2)}
              deliveryAmount={deliveryAmount?.toFixed(2)}
              totalAmount={totalAmount?.toFixed(2)}
              taxAmount={taxAmount?.toFixed(2)}
              vendorSmallOrderFee={vendorSmallOrderFee?.toFixed(2)}
              deliveryType={deliveryType}
              catererTaxType={catererTaxType}
              customMenuItems={customMenuItems}
              orderVariations={orderVariations}
              surchargeName={surchargeName}
            />
          </tbody>
        </table>
      </div>
    );
  }
}

export default withI18n(OrderBasket);
