import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/orderVendorStatementConstants';

const apiUrl = (orderId) => `/orders/${orderId}/vendor_statement_for_orders`;

const {
  FINDING_ORDER_VENDOR_STATEMENT,
  FIND_ORDER_VENDOR_STATEMENT_FAILURE,
  FIND_ORDER_VENDOR_STATEMENT_SUCCESS,
} = actionTypes;

export function findOrderVendorStatement(orderId) {
  return api.get({
    types: [FINDING_ORDER_VENDOR_STATEMENT, FIND_ORDER_VENDOR_STATEMENT_SUCCESS, FIND_ORDER_VENDOR_STATEMENT_FAILURE],
    endpoint: apiUrl(orderId),
    params: null,
    id: false,
  });
}
