import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import VendorStatementsContainer from '../VendorStatements/VendorStatementsContainer';
import BillingDetailsContainer from '../BillingDetails/BillingDetailsContainer';
import BillingsRouterContainer from '../BillingsRouterContainer';

const Vendor = () => (
  <Router>
    <BillingsRouterContainer>
      <Switch>
        <Route path="/billings/vendor_billings" component={BillingDetailsContainer} />
        <Route path="/billings/vendor_statements" component={VendorStatementsContainer} />
        <Route path="/billings" component={VendorStatementsContainer} />
        <Route path="/v2/billings" component={VendorStatementsContainer} />
      </Switch>
    </BillingsRouterContainer>
  </Router>
);

export default Vendor;

