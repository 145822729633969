/* eslint camelcase: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/draftOrderConstants';

export const $$initialState = Immutable.fromJS({
  newItemIds: [],
  orderVariations: {},
});

export default function orderVariationsReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.FETCHING_DRAFT_ORDER: {
      const { params } = response;
      return $$state.withMutations(state => (
        state.update('newItemIds', newItemIdsState => newItemIdsState.concat(params.newItemIds).toSet().toList())
      ));
    }

    case actionTypes.FETCH_DRAFT_ORDER_SUCCESS: {
      const { consolidated_order_variations, order_variations } = response;

      const newItemIds = $$state.get('newItemIds');
      const newOrderVariations = order_variations.map(orderVariation => (
        { ...orderVariation, new: newItemIds.includes(orderVariation.uuid) }
      ));

      return $$state.withMutations(state => (
        state
          .set('orderVariations', Immutable.fromJS(newOrderVariations))
          .set('consolidatedOrderVariations', Immutable.fromJS(consolidated_order_variations))
      ));
    }
    default: {
      return $$state;
    }
  }
}
