import React, { Fragment } from 'react';
import { bool, object, string } from 'prop-types';
import { notBlank } from 'libs/support/string';
import ConsolidatedOrderVariation from '../OrderVariation/ConsolidatedOrderVariation';
import ConsolidatedOptions from '../OrderVariation/OptionsList/ConsolidatedOptionsList';
import ConsolidatedAdditionalInstructions from '../OrderVariation/AdditionalInstructions/ConsolidatedAdditionalInstructions';
import ConsolidatedCustomMenuItem from '../CustomMenuItem/ConsolidatedCustomMenuItem';
import OrderVariationDescription from '../OrderVariation/Description/Description';

const ODD = 'odd';
const EVEN = 'even';

const getRowCount = (consolidatedOrderVariation) => {
  let count = 1;

  count += consolidatedOrderVariation.get('order_variation_option_choices').size;
  count += consolidatedOrderVariation.get('order_variation_item_add_on_options').size;
  count += consolidatedOrderVariation.get('order_variation_service_add_on_options').size;

  const description = consolidatedOrderVariation.get('menu_item_description');
  const displayDescription = consolidatedOrderVariation.get('menu_item_display_description');
  if (notBlank(description) && displayDescription) {
    count += 1;
  }

  if (notBlank(consolidatedOrderVariation.get('additional_instructions'))) {
    count += 1;
  }

  return count;
};

const ItemsSummary = ({
  customMenuItems,
  consolidatedOrderVariations,
  isGroupOrder,
  showTotal,
  userName,
  hideUserName,
}) => (
  <Fragment>
    {
      consolidatedOrderVariations.map((consolidatedOrderVariation, index) => {
        const id = consolidatedOrderVariation.get('uuid');
        const className = ((index % 2) !== 0) ? EVEN : ODD;
        const totalPrice = consolidatedOrderVariation.get('total_price');

        return [
          <ConsolidatedOrderVariation
            key={`consolidated-order-variation-${id}`}
            quantity={consolidatedOrderVariation.get('quantity')}
            className={className}
            nameString={consolidatedOrderVariation.get('name_string')}
            labels={consolidatedOrderVariation.get('labels')}
            userName={userName}
            rowCount={getRowCount(consolidatedOrderVariation)}
            isGroupOrder={isGroupOrder}
            totalPrice={totalPrice ? totalPrice.toFixed(2) : ''}
            showTotal={showTotal}
            hideUserName={hideUserName}
          />,
          <OrderVariationDescription
            key={`consolidated-order-variation-description-${id}`}
            className={className}
            description={consolidatedOrderVariation.get('menu_item_description')}
            displayDescription={consolidatedOrderVariation.get('menu_item_display_description')}
          />,
          <ConsolidatedOptions
            key="choices"
            type="choice"
            indexLang="menuItemsTableHeadersOptions"
            className={className}
            choices={consolidatedOrderVariation.get('order_variation_option_choices')}
          />,
          <ConsolidatedOptions
            key="item-add-on-options"
            type="item-add-on-option"
            indexLang="menuItemsTableHeadersOptions"
            className={className}
            choices={consolidatedOrderVariation.get('order_variation_item_add_on_options')}
          />,
          <ConsolidatedOptions
            key="service-add-on-options"
            type="service-add-on-option"
            indexLang="menuItemsTableHeadersServiceAddOns"
            className={className}
            choices={consolidatedOrderVariation.get('order_variation_service_add_on_options')}
          />,
          <ConsolidatedAdditionalInstructions
            key="additional-instructions"
            className={className}
            value={consolidatedOrderVariation.get('additional_instructions')}
          />,
        ];
      })
    }

    {
      customMenuItems.map((customMenuItem, index) => {
        const id = customMenuItem.get('uuid');
        const className = (((consolidatedOrderVariations.size + index) % 2) !== 0) ? EVEN : ODD;

        return (
          <ConsolidatedCustomMenuItem
            key={`custom-menu-item-${id}`}
            className={className}
            customMenuItem={customMenuItem}
            showTotal={showTotal}
          />
        );
      })
    }
  </Fragment>
);

ItemsSummary.propTypes = {
  consolidatedOrderVariations: object,
  customMenuItems: object,
  isGroupOrder: bool,
  showTotal: bool.isRequired,
  userName: string,
  hideUserName: bool.isRequired,
};

export default ItemsSummary;
