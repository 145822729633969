import React from 'react';
import { bool, object, string } from 'prop-types';
import VirtualActivity from './VirtualActivity';
import InOfficeActivity from './InOfficeActivity';
import OffSiteActivity from './OffSiteActivity';

const COMPONENTS = {
  activities_virtual_activity_kit_delivery: VirtualActivity,
  activities_virtual_activity_only: VirtualActivity,
  activities_in_office_activity: InOfficeActivity,
  activities_off_site_activity: OffSiteActivity,
};

const EventLocation = ({
  address,
  additionalInstructions,
  isMultiLocation,
  menuItem,
  tagValue,
  outlet,
}) => {
  if (!menuItem) return null;

  const Component = COMPONENTS[tagValue];

  if (!Component) return null;

  return (
    <Component
      {
        ...{
          address,
          additionalInstructions,
          isMultiLocation,
          tagValue,
          outlet,
        }
      }
    />
  );
};

EventLocation.propTypes = {
  address: object,
  additionalInstructions: string,
  isMultiLocation: bool.isRequired,
  menuItem: object,
  tagValue: string,
  outlet: object.isRequired,
};

export default EventLocation;
