import React from 'react';
import { IntlProvider } from 'react-intl';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';
import DraftOrdersBanner from '../components/DraftOrdersBanner';

const DraftOrderBannerContainer = (props) => {
  const locale = defaultLocale;
  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <DraftOrdersBanner {...props} />
    </IntlProvider>
  );
};

export default DraftOrderBannerContainer;
