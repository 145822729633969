import Immutable from 'immutable';
import * as actionTypes from '../constants/orderVendorStatementConstants';

export const $$initialState = Immutable.fromJS({
  isFinding: false,
  isFindSuccess: false,
  vendorStatement: {},
});

export default function orderVendorStatementReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  const defaultHash = {
    isFinding: false,
    isFindSuccess: false,
  };

  switch (type) {
    case actionTypes.FIND_ORDER_VENDOR_STATEMENT_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFindSuccess', true)
          .set('vendorStatement', Immutable.fromJS(response.vendor_statement))
      ));
    }

    case actionTypes.FINDING_ORDER_VENDOR_STATEMENT: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFinding', true)
      ));
    }

    default: {
      return $$state;
    }
  }
}
