import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/orderConstants';

function buildStatusAction(action, types, orderId, params = {}) {
  return api.save({
    types,
    endpoint: `/orders/${orderId}/${action}`,
    params,
  });
}

export function changeOrderTab(response) {
  return { type: actionTypes.CHANGE_ORDER_TAB, response };
}

export function confirmOrder(orderId) {
  return buildStatusAction(
    'confirm',
    [
      actionTypes.CONFIRMING_ORDER,
      actionTypes.CONFIRM_ORDER_SUCCESS,
      actionTypes.CONFIRM_ORDER_FAILURE,
    ],
    orderId,
  );
}

// them middlewarers dont need to know
export function declineOrder(orderId, params) {
  return buildStatusAction(
    'decline',
    [
      actionTypes.DECLINING_ORDER,
      actionTypes.DECLINE_ORDER_SUCCESS,
      actionTypes.DECLINE_ORDER_FAILURE,
    ],
    orderId,
    params,
  );
}

export function confirmOrderChanges(orderId) {
  return buildStatusAction(
    'confirm_changes',
    [
      actionTypes.CONFIRMING_ORDER_CHANGES,
      actionTypes.CONFIRM_ORDER_CHANGES_SUCCESS,
      actionTypes.CONFIRM_ORDER_CHANGES_FAILURE,
    ],
    orderId,
  );
}

export function declineOrderChanges(orderId) {
  return buildStatusAction(
    'decline_changes',
    [
      actionTypes.DECLINING_ORDER,
      actionTypes.DECLINE_ORDER_CHANGES_SUCCESS,
      actionTypes.DECLINE_ORDER_CHANGES_FAILURE,
    ],
    orderId,
  );
}

export function confirmCancellationRequest(orderId) {
  return buildStatusAction(
    'cancel_by_customer',
    [
      actionTypes.CONFIRMING_CANCELLATION_REQUEST,
      actionTypes.CONFIRM_CANCELLATION_REQUEST_SUCCESS,
      actionTypes.CONFIRM_CANCELLATION_REQUEST_FAILURE,
    ],
    orderId,
  );
}

export function selectOrderView(orderActiveView) {
  return { type: actionTypes.SELECT_ORDER_VIEW, response: { orderActiveView } };
}
