import React from 'react';
import { func, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';
import map from 'lodash/map';

const FIELDS = ['street_address', 'unit_address', 'postcode', 'city_full_name', 'additional_instructions'];
const ADDITIONAL_INSTRUCTIONS = 'additional_instructions';

const DeliveryLocation = ({
  address,
  additionalInstructions,
  translate,
}) => (
  <div className="delivery-location">
    <div className="group-actions">
      <div className="row">
        <div className="col-md-12">
          <div className="heading-title">
            <h6>
              {translate('ordersShowFormsCustomerDeliveryInformation')}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div className="content-box">
      <form>
        <fieldset>
          <div
            className="form-group row"
          >
            <label className="col-sm-3 form-control-label">
              {translate('ordersShowFormsCustomerFieldsDeliveryType')}
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="order[delivery_type]"
                className="form-control"
                defaultValue="Single-location delivery"
              />
            </div>
          </div>
          {
              map(FIELDS, field => (

                <div
                  key={`input-${field}`}
                  className="form-group row"
                >
                  <label className="col-sm-3 form-control-label">
                    {translate(`ordersShowFormsCustomerFields${uppercamelcase(field)}`)}
                  </label>
                  <div className="col-sm-9">
                    {
                      (field === ADDITIONAL_INSTRUCTIONS) ?
                        <textarea
                          name="address[additional_instructions]"
                          className="form-control"
                          rows="3"
                          defaultValue={additionalInstructions}
                        /> :
                        <input
                          type="text"
                          name={`address[${field}]`}
                          className="form-control"
                          defaultValue={address.get(field)}
                        />
                    }
                  </div>
                </div>
              ))
            }
        </fieldset>
      </form>
    </div>
  </div>
);

DeliveryLocation.propTypes = {
  address: string,
  additionalInstructions: string,
  translate: func.isRequired,
};

export default withI18n(DeliveryLocation);
