import React from 'react';
import { func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import Header from './Header';

const VirtualActivity = ({
  translate,
}) => (
  <div className="event-location">
    <Header />
    <div className="content-box">
      <div className="form-group row">
        <label className="col-sm-3 form-control-label">
          {translate('eventLocationLabelsLocation')}
        </label>

        <div className="col-sm-9" style={{ height: '40px', padding: '10px 16px' }}>
          {translate('eventLocationMessagesActivitiesVirtualActivityMessage')}
        </div>
      </div>
    </div>
  </div>
);

VirtualActivity.propTypes = {
  translate: func.isRequired,
};

export default withI18n(VirtualActivity);
