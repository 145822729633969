import * as api from 'libs/middlewares/api';

import * as actionTypes from '../constants/dashboardConstants';

const apiUrl = '/v2/dashboards/overview';

export function fetchDashboardData(params = {}) {
  const types = [
    actionTypes.FETCHING_DASHBOARDS_DATA,
    actionTypes.FETCH_DASHBOARDS_DATA_SUCCESS,
    actionTypes.FETCH_DASHBOARDS_DATA_FAILURE,
  ];
  return api.get({
    types,
    endpoint: apiUrl,
    params,
    id: null,
  });
}
