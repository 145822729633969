/* eslint react/no-array-index-key: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { blankish } from 'libs/support/string';
import Modal from 'libs/components/ModalComponent';
import ModalHeader from 'libs/components/ModalHeaderComponent';
import ModalBody from 'libs/components/ModalBodyComponent';
import HistoryWithDiff from '../OrderHistories/HistoryWithDiff.jsx';
import HistoryWithoutDiff from '../OrderHistories/HistoryWithoutDiff.jsx';
import HistoryWithoutDiffV2 from '../OrderHistories/HistoryWithoutDiffV2.jsx';
import DeclineReason from '../OrderHistories/DeclineReason.jsx';

const OrderHistoryModal = ({ order, orderHistory, onHide }) => {
  const orderDeclined = order.get('status') === 'declined';

  return (
    <Modal
      show={!blankish(orderHistory.get('uuid'))}
      className="modal-lg"
      onHide={onHide}
    >
      <ModalHeader enableClose>
        <h5 className="modal-title">
          {
            `Order History - ${moment(orderHistory.get('created_at'))
              .format('DD MMM YYYY, h:mm A')}`
          }
        </h5>
      </ModalHeader>

      <ModalBody>
        {
          (orderHistory.size > 0) &&
          <div className="modal-body-content container-fluid order-diff">
            {
              orderDeclined ?
                <DeclineReason
                  reasons={order.get('decline_reasons')}
                  detail={order.get('decline_detail')}
                /> :
                <div>
                  {
                    blankish(orderHistory.getIn(['data', 'versi'])) &&
                    orderHistory.get('data_diff') === null &&
                    <HistoryWithoutDiff orderHistory={orderHistory} />
                  }

                  {
                    orderHistory.getIn(['data', 'versi']) > 1 &&
                    orderHistory.get('data_diff') === null &&
                    <HistoryWithoutDiffV2 orderHistory={orderHistory} />
                  }

                  {
                    orderHistory.get('data_diff') !== null &&
                    <HistoryWithDiff diff={orderHistory.get('data_diff')} />
                  }
                </div>
            }
          </div>
        }
      </ModalBody>
    </Modal>
  );
};

OrderHistoryModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  orderHistory: PropTypes.object,
  order: PropTypes.object,
};

export default OrderHistoryModal;
