import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import BaseComponent from 'libs/components/BaseComponent';
import NewTag from './NewTag';

export default class OrderBasketOrderVariation extends BaseComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    isCafeOrdering: PropTypes.bool.isRequired,
    orderDateTime: PropTypes.string,
    nameString: PropTypes.string.isRequired,
    newItem: PropTypes.bool,
    quantity: PropTypes.number.isRequired,
    vendorPriceBasedOnTaxType: PropTypes.number.isRequired,
    vendorTotalPriceBasedOnTaxType: PropTypes.number.isRequired,
  };

  static defaultProps = {
    orderDateTime: null,
  }

  render() {
    const {
      className,
      isCafeOrdering,
      nameString,
      newItem,
      orderDateTime,
      quantity,
      vendorPriceBasedOnTaxType,
      vendorTotalPriceBasedOnTaxType,
    } = this.props;

    return (
      <tr className={`${className} order-variation`}>
        {
          isCafeOrdering &&
          <td>
            {
              moment(orderDateTime).format('h:mm A')
            }
          </td>
        }
        <td colSpan="2">
          { nameString }
          { newItem && <span className="ml-1"><NewTag /></span> }
        </td>
        <td>{vendorPriceBasedOnTaxType?.toFixed(2)}</td>
        <td>{quantity}</td>
        <td>{vendorTotalPriceBasedOnTaxType?.toFixed(2)}</td>
      </tr>
    );
  }
}
