/* eslint no-unused-expressions: 0 */
/* eslint no-unused-vars: 0 */

import React, { Fragment, useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';
import EventLocation from 'bundles/orders/components/Order/OrderForms/CustomerForm/EventLocation';
import DeliveryLocation from 'bundles/orders/components/Order/OrderForms/CustomerForm/DeliveryLocation';
import MultiLocation from 'bundles/orders/components/Order/OrderForms/CustomerForm/MultiLocation';
import { getFirstFormat } from 'libs/support/utils';

const Location = ({
  address,
  additionalInstructions,
  isMultiLocation,
  menuItems,
  onFetchMenuItemsByUuids,
  orderRecipientCsv,
  orderVariations,
  outlet,
  translate,
  vendorKind,
  flagIsEnabled,
}) => {
  const [isInitialized, setInitialize] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setInitialize(true);

      const uuids = orderVariations.map((ov) => ov.get('menu_item_id')).toJS();

      onFetchMenuItemsByUuids && onFetchMenuItemsByUuids(uuids);
    }
  });

  const menuItem = menuItems.size > 0 && menuItems.get(0);
  const tagValue = menuItem && getFirstFormat(menuItem, 'activities', 'tag');
  const showLocation = vendorKind !== 'activities' ||
    tagValue === 'activities_virtual_activity_kit_delivery';

  return (
    <Fragment>
      {
        vendorKind === 'activities' &&
          <EventLocation
            {
              ...{
                address,
                additionalInstructions,
                isMultiLocation,
                menuItem,
                tagValue,
                outlet,
              }
            }
          />
      }

      {
        showLocation &&
          (isMultiLocation ?
            <MultiLocation
              {
                ...{
                  orderRecipientCsv,
                }
              }
            /> :
            <DeliveryLocation
              {
                ...{
                  address,
                  additionalInstructions,
                }
              }
            />
          )
      }
    </Fragment>
  );
};

Location.propTypes = {
  address: object,
  additionalInstructions: string,
  flagIsEnabled: func.isRequired,
  isMultiLocation: bool.isRequired,
  menuItems: object,
  onFetchMenuItemsByUuids: func.isRequired,
  orderRecipientCsv: object,
  orderVariations: object.isRequired,
  outlet: object.isRequired,
  translate: func.isRequired,
  vendorKind: string.isRequired,
};

export default withAppContext(withI18n(Location));
