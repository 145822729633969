/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable quotes */
import React, { Fragment } from 'react';
import classnames from 'classnames';
import { array, func, string } from 'prop-types';
import { trackClickedTodayFilter, trackClickedTomorrowFilter, trackClickedSeeAllOrdersButton } from "packs/events";

const DesktopOrderListHeader = ({
  orders,
  tomorrowOrders,
  tab,
  setTab,
  tabTypes,
}) => {
  const handleTodayTabOnClick = () => {
    trackClickedTodayFilter();
    setTab(tabTypes.today);
  };

  const handleTomorrowTabOnClick = () => {
    trackClickedTomorrowFilter();
    setTab(tabTypes.tomorrow);
  };

  const handleSeeAllOrdersOnClick = () => {
    trackClickedSeeAllOrdersButton();
    window.location.href = '/v2/orders';
  };

  return (
    <Fragment>
      <div className="d-flex mt-2" style={{ alignItems: 'center' }}>
        <div
          style={{
              padding: '5px',
              backgroundColor: '#F4F5F5',
              border: '1px solid #F4F5F5',
              borderRadius: '8px',
              marginRight: '20px',
            }}
        >
          <div
            className="d-flex"
            style={{
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#F4F5F5',
                border: '1px',
                borderRadius: '8px',
              }}
          >
            <div
              className={classnames('d-flex pointer', {
                  'other-tab': tab !== tabTypes.today,
                  'active-tab': tab === tabTypes.today,
                })}
              onClick={handleTodayTabOnClick}
            >
              <span
                className="tab-text"
              >
                Today
              </span>
              <span
                className={classnames({
                    'active-tab-value': tab === tabTypes.today,
                    'other-tab-value': tab !== tabTypes.today,
                  })}
              >
                {orders?.length}
              </span>
            </div>
            <div
              className={classnames('d-flex pointer', {
                  'active-tab': tab === tabTypes.tomorrow,
                  'other-tab': tab !== tabTypes.tomorrow,
                })}
              onClick={handleTomorrowTabOnClick}
            >
              <span
                className="tab-text"
              >
                Tomorrow
              </span>
              <span
                className={classnames({
                    'active-tab-value': tab === tabTypes.tomorrow,
                    'other-tab-value': tab !== tabTypes.tomorrow,
                  })}
              >
                {tomorrowOrders?.length}
              </span>
            </div>
          </div>
        </div>
        <span
          className="pointer"
          onClick={handleSeeAllOrdersOnClick}
        >
          See All Orders
        </span>
      </div>
    </Fragment>
  );
};

DesktopOrderListHeader.propTypes = {
  orders: array,
  tomorrowOrders: array,
  tab: string,
  setTab: func,
  tabTypes: string,
};

export default DesktopOrderListHeader;
