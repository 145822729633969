/* eslint max-len: 0 */
import React from 'react';

const Info = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8C0 12.4113 3.58873 16 8.00004 16C12.4113 16 16 12.4113 16 8C16 3.58869 12.4113 0 8.00004 0C3.58873 0 0 3.58873 0 8ZM8.18345 4.44334C8.18345 3.58286 8.81401 3 9.59247 3C10.3694 3 11 3.58286 11 4.44334C11 5.30388 10.3694 5.88667 9.59247 5.88667C8.81401 5.88667 8.18345 5.30394 8.18345 4.44334ZM7.91963 12.0805C8.07085 12.1248 8.38964 11.9609 8.64635 11.7264L9.88415 10.4795C9.90724 10.6206 9.88925 10.8344 9.8752 11.0013V11.0013C9.86849 11.0812 9.86267 11.1503 9.86267 11.1957C8.93228 12.3644 7.98543 13.2662 6.39235 13.2662C5.30477 13.1181 4.85844 12.4662 5.09438 11.802L7.14362 7.56611C7.19285 7.42728 7.10999 7.27837 6.95837 7.23403C6.80675 7.18928 6.51059 7.35398 6.25468 7.58849L5.01608 8.83514C4.99135 8.68304 5.00075 8.45715 5.00763 8.29195C5.01017 8.23088 5.01237 8.17811 5.01237 8.14042C5.94341 6.97134 7.4718 6.04952 8.50819 6.04952C9.49494 6.13357 9.95973 6.79239 9.78965 7.51751L7.7254 11.7801C7.698 11.9092 7.77965 12.0396 7.91963 12.0805Z"
      fill="#7D9E1D"
    />
  </svg>
);

export default Info;
