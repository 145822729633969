import Immutable from 'immutable';
import * as actionTypes from '../constants/orderConstants';

export const $$initialState = Immutable.fromJS({
  orderHistories: [],
  selectedOrderHistory: {},
});

export default function orderHistoriesReducer($$state = $$initialState, action = null) {
  const { type, response } = action;


  switch (type) {
    case actionTypes.SHOW_ORDER_HISTORY: {
      return $$state.set('selectedOrderHistory', Immutable.fromJS(response));
    }
    case actionTypes.HIDE_ORDER_HISTORY: {
      return $$state.set('selectedOrderHistory', Immutable.fromJS({}));
    }
    default: {
      return $$state;
    }
  }
}
