import Immutable from 'immutable';
import humps from 'lodash-humps';
import * as actionTypes from '../constants/orderBillingConstants';

export const $$initialState = Immutable.fromJS({
  flash: {},
  isFinding: false,
  isFindSuccess: false,
  isSaving: false,
  isSaveSuccess: false,
  isShowEditModal: false,
  editField: null,
  orderBilling: {},
});

export default function orderBillingReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  const defaultHash = {
    isFinding: false,
    isSaving: false,
    isSaveSuccess: false,
  };

  switch (type) {
    case actionTypes.FIND_ORDER_BILLING_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('orderBilling', Immutable.fromJS(humps(response.order_billing)))
          .set('isFindSuccess', true)
      ));
    }

    case actionTypes.FINDING_ORDER_BILLING: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isFinding', true)
      ));
    }

    case actionTypes.SAVE_ORDER_BILLING_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('orderBilling', Immutable.fromJS(humps(response.order_billing)))
          .set('flash', Immutable.fromJS(response.flash))
          .set('isSaveSuccess', true)
      ));
    }

    case actionTypes.SAVE_ORDER_BILLING_FAILURE: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('flash', Immutable.fromJS({ errors: response.errors }))
      ));
    }

    case actionTypes.SAVING_ORDER_BILLING: {
      return $$state.withMutations(state => (
        state
          .merge(defaultHash)
          .set('isSaving', true)
          .set('flash', Immutable.fromJS({}))
      ));
    }

    case actionTypes.SHOW_ORDER_BILLING_EDIT_MODAL: {
      return $$state.withMutations(state => (
        state
          .set('isShowEditModal', true)
          .set('editField', response.editField)
      ));
    }

    case actionTypes.HIDE_ORDER_BILLING_EDIT_MODAL: {
      return $$state.withMutations(state => (
        state
          .set('isShowEditModal', false)
      ));
    }

    default: {
      return $$state;
    }
  }
}
