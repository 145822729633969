/* eslint max-len: 0 */

import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import Intl from 'intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import { translations } from 'libs/i18n/translations';

import Location from 'bundles/orders/components/Order/OrderForms/CustomerForm/Location';
import * as menuItemsActions from '../actions/menuItemsActions';

global.Intl = Intl;

addLocaleData([...en, ...zh]);

const LocationContainer = ({
  address,
  additionalInstructions,
  isMultiLocation,
  localeStore,
  menuItems,
  onFetchMenuItemsByUuids,
  orderRecipientCsv,
  orderVariations,
  outlet,
  vendorKind,
}) => {
  const locale = localeStore.get('locale');
  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Location
        {...{
          address,
          additionalInstructions,
          isMultiLocation,
          menuItems,
          orderVariations,
          orderRecipientCsv,
          onFetchMenuItemsByUuids,
          outlet,
          vendorKind,
        }}
      />
    </IntlProvider>
  );
};

LocationContainer.propTypes = {
  address: object,
  additionalInstructions: string,
  isMultiLocation: bool.isRequired,
  localeStore: object.isRequired,
  menuItems: object.isRequired,
  onFetchMenuItemsByUuids: func.isRequired,
  orderRecipientCsv: object,
  orderVariations: object.isRequired,
  outlet: object.isRequired,
  vendorKind: string.isRequired,
};

const mapStatesToProps = (state) => {
  const {
    $$catererStore,
    $$localeStore,
    $$menuItemsStore,
    $$orderStore,
    $$orderVariationsStore,
  } = state;

  return {
    address: $$orderStore.getIn(['order', 'address']),
    additionalInstructions: $$orderStore.getIn(['order', 'additional_instructions']),
    isMultiLocation: $$orderStore.getIn(['order', 'multi_location']),
    localeStore: $$localeStore,
    menuItems: $$menuItemsStore.get('menuItems'),
    orderRecipientCsv: $$orderStore.getIn(['order', 'order_recipient_csv']),
    orderVariations: $$orderVariationsStore.get('orderVariations'),
    outlet: $$catererStore.getIn(['caterer', 'outlet']),
    vendorKind: $$catererStore.getIn(['caterer', 'kind']),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onFetchMenuItemsByUuids: menuItemsActions.fetchMenuItemsByUuids,
}, dispatch);

export default connect(mapStatesToProps, mapDispatchToProps)(LocationContainer);
