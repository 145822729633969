/* eslint max-len: 0 */

import React from 'react';
import uppercamelcase from 'uppercamelcase';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { func, object, string } from 'prop-types';

import { defaultMessages } from 'libs/i18n/default';
import { blankish } from 'libs/support/string';
import { prevent } from 'libs/support/prevent';

const TAB = 'order_history';
const THEAD = [['date', '25%'], ['status', '25%'], ['user', '25%'], ['', '25%']];

const OrderHistories = ({ activeTab, intl, onShow, orderHistories }) => {
  if (activeTab !== TAB) return null;

  const { formatMessage } = intl;

  return (
    <div id="order-histories">
      <div className="group-actions">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-title">
              <h6>
                {formatMessage(defaultMessages.ordersShowFormsOrderHistoriesTitle)}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="content-box">
        <table className="table table-basket">
          <thead>
            <tr>
              {
                THEAD.map(data => (
                  <th key={`header-${data[0]}`} width={data[1]}>
                    {
                      !blankish(data[0]) &&
                      formatMessage(defaultMessages[`ordersShowFormsOrderHistoriesTable${uppercamelcase(data[0])}`])
                    }
                  </th>
                ))
              }
            </tr>
          </thead>

          <tbody>
            {
              orderHistories.map(orderHistory => {
                const dateTime = moment(orderHistory.get('created_at'));
                const state = orderHistory.get('state');

                return (
                  <tr className="odd" key={`body-${orderHistory.get('uuid')}`}>
                    <td>{dateTime.format('DD MMM YYYY h:mm A')}</td>
                    <td>{formatMessage(defaultMessages[`ordersStates${uppercamelcase(state)}`])}</td>
                    <td>{orderHistory.get('user_name')}</td>
                    <td>
                      <a href="#" onClick={prevent(onShow, orderHistory)}>
                        View
                      </a>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

OrderHistories.propTypes = {
  activeTab: string.isRequired,
  intl: intlShape.isRequired,
  onShow: func.isRequired,
  orderHistories: object.isRequired,
};

export default injectIntl(OrderHistories);
