import React, { Fragment } from 'react';
import {
  bool,
  func,
  object,
  string,
} from 'prop-types';
import renderHTML from 'react-render-html';

import { notBlank } from 'libs/support/string';
import { withI18n } from 'libs/support/i18n';

const ConsolidatedCustomMenuItem = ({
  className,
  customMenuItem,
  showTotal,
  translate,
}) => {
  const price = customMenuItem.get('price');
  const quantity = customMenuItem.get('quantity');
  const finalQuantity = parseInt(quantity, 10);
  const description = customMenuItem.get('description');
  const specialInstructions = customMenuItem.get('special_instructions');
  const totalPrice = (price * finalQuantity).toFixed(2);

  return (
    <tr className={`${className} consolidated-custom-menu-item`}>
      <td className="strong">{finalQuantity}x</td>
      <td >
        {customMenuItem.get('name')}

        {
          notBlank(description) &&
          <div>{renderHTML(description)}</div>
        }

        {
          notBlank(specialInstructions) &&
          <Fragment>
            <br />
            <div>
              {translate('ordersShowFormsCustomMenuItemsTermsAdditionalInstructions')}:
              <br />
              {renderHTML(specialInstructions)}
            </div>
          </Fragment>
        }
      </td>

      <td colSpan={showTotal ? '1' : '3'} />

      {
        showTotal &&
        <td colSpan="2">{totalPrice}</td>
      }
    </tr>
  );
};

ConsolidatedCustomMenuItem.propTypes = {
  className: string.isRequired,
  customMenuItem: object.isRequired,
  showTotal: bool,
  translate: func.isRequired,
};

export default withI18n(ConsolidatedCustomMenuItem);
