import React from 'react';
import { number, string, object } from 'prop-types';

import AmountRowsV1 from './v1/AmountRows';
import AmountRowsV2 from './v2/AmountRows';
import AmountRowsV4 from './v4/AmountRows';

/**
 * AmountRows
 */

const AmountRows = ({
  caterspotMarkupAmount,
  versi,
  discountAmount,
  caterspotRefund,
  voucherCode,
  subtotalAmount,
  vendorRefund,
  surchargeAmount,
  deliveryAmount,
  totalAmount,
  taxAmount,
  vendorSmallOrderFee,
  deliveryType,
  catererTaxType,
  customMenuItems,
  orderVariations,
  surchargeName,
}) => {
  if (versi > 3) {
    return (
      <AmountRowsV4
        subtotalAmount={subtotalAmount}
        vendorRefund={vendorRefund}
        surchargeAmount={surchargeAmount}
        deliveryAmount={deliveryAmount}
        totalAmount={totalAmount}
        taxAmount={taxAmount}
        deliveryType={deliveryType}
        catererTaxType={catererTaxType}
        customMenuItems={customMenuItems}
        orderVariations={orderVariations}
        surchargeName={surchargeName}
        vendorSmallOrderFee={vendorSmallOrderFee}
        caterspotMarkupAmount={caterspotMarkupAmount}
      />
    );
  } else if (versi > 1) {
    return (
      <AmountRowsV2
        subtotalAmount={subtotalAmount}
        vendorRefund={vendorRefund}
        surchargeAmount={surchargeAmount}
        deliveryAmount={deliveryAmount}
        totalAmount={totalAmount}
        taxAmount={taxAmount}
        deliveryType={deliveryType}
        catererTaxType={catererTaxType}
        customMenuItems={customMenuItems}
        orderVariations={orderVariations}
        surchargeName={surchargeName}
      />
    );
  }
  return (
    <AmountRowsV1
      subtotalAmount={subtotalAmount}
      discountAmount={discountAmount}
      vendorRefund={vendorRefund}
      caterspotRefund={caterspotRefund}
      surchargeAmount={surchargeAmount}
      deliveryAmount={deliveryAmount}
      totalAmount={totalAmount}
      taxAmount={taxAmount}
      voucherCode={voucherCode}
      surchargeName={surchargeName}
    />
  );
};

AmountRows.propTypes = {
  caterspotMarkupAmount: number,
  versi: number.isRequired,
  discountAmount: number,
  caterspotRefund: number,
  voucherCode: string,
  subtotalAmount: number,
  vendorRefund: number,
  surchargeAmount: number,
  deliveryAmount: number,
  totalAmount: number,
  taxAmount: number,
  vendorSmallOrderFee: number,
  customMenuItems: object,
  orderVariations: object,
  surchargeName: string,
  deliveryType: string.isRequired,
  catererTaxType: string.isRequired,
};

export default AmountRows;
