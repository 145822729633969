import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { blankish } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

class OrderVariationsListOrderVariationOrderUser extends BaseComponent {
  static propTypes = {
    isCafeOrdering: PropTypes.bool.isRequired,
    isGroupOrder: PropTypes.bool,
    className: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    value: PropTypes.string,
    intl: intlShape.isRequired,
  };

  render() {
    const { isCafeOrdering, isGroupOrder, className, userName, value, intl } = this.props;
    const { formatMessage } = intl;

    if (!isGroupOrder) return null;

    const finalValue = blankish(value) ? userName : value;

    return (
      <tr className={className}>
        { isCafeOrdering && <td className="no-borders" /> }
        <td className="no-borders p-b-15 text-violet" colSpan="5">
          {formatMessage(defaultMessages.ordersShowFormsOrderVariationsTermsFor)}
          <br />
          {finalValue}
        </td>
      </tr>
    );
  }
}

export default injectIntl(OrderVariationsListOrderVariationOrderUser);
