/* eslint jsx-a11y/no-static-element-interactions: 0 */
/* eslint jsx-a11y/click-events-have-key-events: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uppercamelcase from 'uppercamelcase';
import bindAll from 'lodash/bindAll';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { defaultMessages } from 'libs/i18n/default';

class OrderTabsTab extends BaseComponent {
  static propTypes = {
    tab: PropTypes.string.isRequired,
    activeTab: PropTypes.string.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor() {
    super();

    bindAll(this, 'handleOnClick');
  }

  handleOnClick() {
    const { tab, activeTab, onChangeTab } = this.props;

    if (tab !== activeTab) onChangeTab(tab);
  }

  render() {
    const { tab, activeTab, intl } = this.props;
    const { formatMessage } = intl;
    const btnClass = classnames('nav-item', { active: tab === activeTab });

    return (
      <li className={btnClass}>
        <a
          className="nav-link"
          onClick={this.handleOnClick}
        >
          {formatMessage(defaultMessages[`ordersShowTabs${uppercamelcase(tab)}`])}
        </a>
      </li>
    );
  }
}

export default injectIntl(OrderTabsTab);
