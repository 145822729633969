import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';

const Container = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.colors.snowFlurry};
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: #141415;
  text-align: center;
  white-space: nowrap;
  padding: 0 0.5rem;
  opacity: 0.7;
`;

const NewTag = ({ translate }) => (
  <Container>
    { translate('termsNew') }
  </Container>
);

NewTag.propTypes = {
  translate: func.isRequired,
};

export default withI18n(NewTag);
