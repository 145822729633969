/* eslint max-len: 0 */

import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { func, object } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { withAppContext } from 'libs/support/appContext';

import soundfile from 'assets/audios/notification-sound.wav';
import AlertModalContainer from 'bundles/common/containers/AlertModalContainer';
import OrderAside from '../Order/OrderAside/OrderAside';
import Tabs from '../Order/OrderTabs/OrderTabs';
import CustomerForm from '../Order/OrderForms/CustomerForm/CustomerForm';
import CatererForm from '../Order/OrderForms/CatererForm/CatererForm';
import OrderBasket from '../Order/OrderBasket/OrderBasket';
import Header from './Header';

const TABS = ['basket', 'customer', 'vendor'];
const CHANNEL = 'cartChannel';

const DraftOrder = ({
  actions,
  cable,
  catererStore,
  customMenuItemsStore,
  orderableSettingStore,
  orderStore,
  orderVariationsStore,
  userStore,
  translate,
}) => {
  const [errorMsg, setErrorMsg] = useState(translate('draftOrdersShowSocketConnecting'));
  const order = orderStore.get('order');
  const isCafeOrdering = order.get('cafe_ordering');
  const user = userStore.get('user');
  const caterer = catererStore.get('caterer');
  const vendorKind = caterer.get('kind');
  const activeTab = orderStore.get('activeTab');
  const catererName = caterer.get('name');
  const orderDeliveryType = order.get('delivery_type');
  const userEmail = userStore.getIn(['user', 'email']);
  const audio = new Audio(soundfile);
  const channel = useRef(null);

  const playSound = (volume) => {
    audio.volume = volume;
    audio.play().catch(() => {
      if (!isCafeOrdering) return;
      actions.showAlertMessage({
        title: translate('draftOrdersShowPermissionAlertTitle'),
        message: translate('draftOrdersShowPermissionAlertMessage'),
        submit: () => ( // eslint-disable-line
          (audio.volume = volume) && audio.play()
        ),
      });
    });
  };

  const subscribeChannel = useCallback(() => {
    channel.current = cable.subscribe({
      channel: CHANNEL,
      params: { cartId: order.get('uuid') },
      connectedCallback: () => {
        setErrorMsg(null);
      },
      disconnectedCallback: () => {
        setErrorMsg(translate('draftOrdersShowSocketError'));
      },
      receivedCallback: (data) => {
        /* eslint camelcase: 0 */
        if (!data) return;
        const { type, cart_variation_ids } = data;
        const params = (type === 'creation') ? { newItemIds: cart_variation_ids } : {};
        actions.fetchDraftOrder(order.get('uuid'), params).then(() => (
          type === 'creation' && playSound(1)
        ));
      },
    });
  }, []);

  useEffect(() => {
    if (isCafeOrdering) playSound(0.1);

    if (!channel.current) subscribeChannel();

    return function cleanup() { // eslint-disable-line
      if (channel.current) { return cable.unsubscribe(channel.current); }
    };
  }, []);

  return (
    <Fragment>
      <AlertModalContainer />

      <Header deadlineAt={order.get('deadline_at')} />

      <div className="container wrapper" style={{ display: 'table' }}>
        <div className="row no-margin">
          <div className="col-md-12 main-content">
            <div className="row no-margin">
              <div className="col-md-3 sidebar">
                <OrderAside
                  name={`${user.get('first_name')} ${user.get('last_name')}`}
                  catererName={catererName}
                  isDraftOrder
                  orderDeliveryType={orderDeliveryType}
                  orderCreatedAt={order.get('created_at')}
                  orderDeliveryDate={order.get('deliver_at')}
                  orderEndDeliveryDate={order.get('end_deliver_at')}
                  orderPickupDate={order.get('pickup_at')}
                  orderDriverAssigned={order.get('driver_assigned')}
                  orderStatus={order.get('status')}
                  vertical={vendorKind}
                />
              </div>

              <div className="col-md-9 main-content">
                <div className="group-actions py-0">
                  <div className="row">
                    <div className="col-md-8 my-1">
                      <div className="heading-title">
                        <div className="d-inline-block pr-1">
                          <h6>
                            {translate('ordersShowTermsOrderId')}: {order.get('code')}
                          </h6>
                        </div>
                        <div className="d-inline-block pr-1">
                          <h6>
                            {translate('ordersShowTermsCountry')}: {caterer.get('country_full_name')}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Tabs
                  tabs={TABS}
                  vendorKind={vendorKind}
                  activeTab={activeTab}
                  onChangeTab={actions.changeOrderTab}
                  orderDeliveryType={orderDeliveryType}
                />

                {
                  (orderDeliveryType === 'own' || vendorKind === 'activities') &&
                  <CustomerForm
                    activeTab={activeTab}
                    orderDeliveryType={orderDeliveryType}
                    firstName={order.get('vendor_resolved_contact_first_name')}
                    lastName={order.get('vendor_resolved_contact_last_name')}
                    mobile={order.get('vendor_resolved_contact_mobile')}
                    additionalInstructions={order.get('additional_instructions')}
                    address={order.get('address')}
                    vendorKind={vendorKind}
                    email={userEmail}
                  />
                }

                <CatererForm
                  activeTab={activeTab}
                  name={catererName}
                  outlet={caterer.get('outlet')}
                />

                <OrderBasket
                  consolidatedOrderVariations={orderVariationsStore.get('consolidatedOrderVariations')}
                  fflags={orderStore.get('fflags')}
                  isCafeOrdering={order.get('cafe_ordering')}
                  isGroupOrder={order.get('group_order')}
                  activeTab={activeTab}
                  errorMsg={errorMsg}
                  userName={user.get('first_name')}
                  voucherCode={order.get('voucher_code')}
                  surchargeName={order.get('surcharge_name')}
                  orderableSettingStore={orderableSettingStore}
                  orderActiveView={orderStore.get('orderActiveView')}
                  orderVariations={orderVariationsStore.get('orderVariations')}
                  customMenuItemsStore={customMenuItemsStore}
                  orderVersi={parseInt(order.get('versi') || 4, 10)}
                  deliveryType={order.get('delivery_type')}
                  catererTaxType={order.get('tax_type')}
                  subtotalAmount={order.get('v_subtotal_amount')}
                  discountAmount={order.get('v_discount_amount')}
                  vendorRefund={order.get('v_vendor_refund_amount')}
                  caterspotRefund={order.get('v_caterspot_refund_amount')}
                  surchargeAmount={parseFloat(order.get('v_surcharge_amount')) || null}
                  deliveryAmount={order.get('v_delivery_amount')}
                  totalAmount={order.get('v_total_amount')}
                  taxAmount={order.get('v_tax_amount')}
                  vendorSmallOrderFee={order.get('v_vendor_small_order_fee')}
                  caterspotMarkupAmount={order.get('v_caterspot_markup_amount')}
                  hideUserName={order.get('hide_user_name')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DraftOrder.propTypes = {
  actions: object.isRequired,
  cable: object.isRequired,
  catererStore: object.isRequired,
  customMenuItemsStore: object.isRequired,
  orderableSettingStore: object.isRequired,
  orderStore: object.isRequired,
  orderVariationsStore: object.isRequired,
  userStore: object.isRequired,
  translate: func.isRequired,
};

export default withAppContext(withI18n(DraftOrder));
