import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/menuItemsConstants';

export const fetchMenuItemsByUuids = (uuids) => (
  api.get({
    types: [
      actionTypes.FETCHING_MENU_ITEMS_BY_UUIDS,
      actionTypes.FETCH_MENU_ITEMS_BY_UUIDS_SUCCESS,
      actionTypes.FETCH_MENU_ITEMS_BY_UUIDS_FAILURE,
    ],
    endpoint: '/menus/menu_items',
    params: { uuids },
  })
);
