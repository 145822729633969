import React from 'react';
import PropTypes from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import map from 'lodash/map';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import FieldComponent from 'libs/components/FieldComponent';
import { defaultMessages } from 'libs/i18n/default';

const CONTACT_FIELDS = ['email', 'phone', 'mobile', 'street_address', 'unit_building', 'city', 'postcode'];
const TAB = 'vendor';

class OrderCatererForm extends BaseComponent {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cancellationPeriod: PropTypes.string,
    openingHours: PropTypes.string,
    orderNotice: PropTypes.any,
    outlet: PropTypes.object,
    intl: intlShape.isRequired,
  };

  render() {
    const { activeTab, name, outlet, intl } = this.props;
    const { formatMessage } = intl;

    if (activeTab !== TAB) return null;

    return (
      <div>
        <div className="group-actions">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-title">
                <h6>{formatMessage(defaultMessages.ordersShowFormsCatererVendorInformation)}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="content-box">
          <form>
            <fieldset>
              <div className="form-group row">
                <label className="col-sm-3 form-control-label">
                  {formatMessage(defaultMessages.ordersShowFormsCatererFieldsOutlet)}
                </label>

                <div className="col-sm-9">
                  {outlet.get('name')}
                </div>
              </div>

              <FieldComponent
                label="ordersShowFormsCatererFieldsName"
                name="vendor[title]"
                type="text"
                value={name}
              />

              {
                map(CONTACT_FIELDS, field => (
                  <FieldComponent
                    key={`input-${field}`}
                    label={`ordersShowFormsCatererFields${uppercamelcase(field)}`}
                    name={`vendor[${field}]`}
                    type="text"
                    value={outlet.get(field)}
                    onChange={() => {}}
                  />
                ))
              }
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

export default injectIntl(OrderCatererForm);
