import { blankish } from 'libs/support/string';

const EXCLUDE_STRING = '@caterspot.com';

export const sanitizedEmails = (emails) => {
  if (blankish(emails)) return emails;

  return emails
    .split(',')
    .filter((email) => !email.includes(EXCLUDE_STRING))
    .map((email) => email.replace(/^\s+|\s+$/g, ''))
    .join(', ');
};
