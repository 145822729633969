import React, { useState } from 'react';
import { func } from 'prop-types';
import styled from 'styled-components';
import { withI18n } from 'libs/support/i18n';
import Info from 'libs/components/Icons/Info';

const Container = styled.div`
  adding: 25px 60px;
  background: ${props => props.theme.colors.white};
  border: 4px solid #7D9E1D;
  box-shadow: 0px 1px 0px ${props => props.theme.colors.gray200};
  border-radius: 3px;
  display: flex;

  > div:first-child {
    width: 90%;
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakPoints.sm}) {
      flex-direction: row;
    }
  }

  > div:last-child {
    width: 10%;
  }
`;

const LeftContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  font-family: ${props => props.theme.fonts.geomanistbook};

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    width: 24%;
  }

  > svg {
    flex-shrink: 0;
  }

  > div {
    font-size: 16px;
    line-height: 21px;
    padding-left: 1rem;
  }

`;

const RightContainer = styled.div`
  width: 100%;
  padding: 1.2rem;
  display: flex;

  @media (min-width: ${props => props.theme.breakPoints.sm}) {
    width: 76%;
  }

  > ol {
    font-size: 14px;
    line-height: 21px;
    padding-left: 1rem;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const CloseButton = styled.button`
  display: flex;
  color: ${props => props.theme.colors.greyChateau} !important;
  opacity: 1 !important;
  font-size: 32px !important;
  padding: 0.8rem !important;
`;

const DraftOrderBannerContainer = ({
  translate,
}) => {
  const [isShow, setIsShow] = useState(!localStorage.getItem('hideDraftOrderBanner'));

  if (!isShow) return null;

  const handleOnClose = () => {
    localStorage.setItem('hideDraftOrderBanner', true);
    setIsShow(false);
  };

  return (
    <Container>
      <div>
        <LeftContainer>
          <Info />
          <div>
            { translate('draftOrdersBannerTitle') }
          </div>
        </LeftContainer>
        <RightContainer>
          <ol>
            <li>
              { translate('draftOrdersBannerExplainationsText1') }
            </li>
            <li>
              { translate('draftOrdersBannerExplainationsText2') }
            </li>
            <li>
              { translate('draftOrdersBannerExplainationsText3') }
            </li>
          </ol>
        </RightContainer>
      </div>
      <div>
        <CloseButton className="close" onClick={handleOnClose}>
          <span>&times;</span>
        </CloseButton>
      </div>
    </Container>
  );
};

DraftOrderBannerContainer.propTypes = {
  translate: func.isRequired,
};

export default withI18n(DraftOrderBannerContainer);
