/* eslint max-len: 0, react/no-danger: 0 */

import React from 'react';
import { func, object } from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { withI18n } from 'libs/support/i18n';

const RecipientUploadBlock = styled.div`
  height: 100%;
  background-color: #f4f5f5;
  padding: 15px;

  > div:nth-of-type(1) {
    margin-bottom: 10px;
  }
`;

const RecipientUploadSubtext = styled.div`
  display: flex;
  flex-direction: column;
  color: #95989D;
  font-size: 14px;
`;
const RecipientUploadWarningText = styled.div`
  padding: 15px;
  color: #515154;
  font-size: 14px;
`;

const MultiLocation = ({
  orderRecipientCsv,
  translate,
}) => (
  <div className="multi-location">
    <div className="group-actions">
      <div className="row">
        <div className="col-md-12">
          <div className="heading-title">
            <h6>
              {translate('ordersShowFormsCustomerDeliveryInformation')}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <div className="content-box">
      <form>
        <fieldset>
          <div
            className="form-group row"
          >
            <label className="col-sm-3 form-control-label">
              {translate('ordersShowFormsCustomerFieldsDeliveryType')}
            </label>
            <div className="col-sm-9">
              <input
                type="text"
                name="order[delivery_type]"
                className="form-control"
                defaultValue="Multi-location delivery"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-3 form-control-label">
              { translate('multiLocationLabelsRecipients') }
            </div>
            <div className="col-sm-9">
              <RecipientUploadBlock>
                <div>
                  <a className="btn btn-sm btn-primary" href={orderRecipientCsv.get('link')}>
                    { translate('multiLocationButtonsDownload') }
                  </a>
                </div>

                <div>
                  { orderRecipientCsv.get('filename') }
                </div>
                <RecipientUploadSubtext>
                  <div>
                    { translate('multiLocationTermsLastUpdated', { date: moment(orderRecipientCsv.get('updated_at')).format('D MMMM YYYY, H:m A') }) }
                  </div>
                  <div>
                    {
                      translate('multiLocationTermsUploaded', {
                        recipient_count: translate('multiLocationTermsRecipient', { count: orderRecipientCsv.get('recipient_count') }),
                        location_count: translate('multiLocationTermsLocation', { count: orderRecipientCsv.get('location_count') }),
                      })
                    }
                  </div>
                </RecipientUploadSubtext>
              </RecipientUploadBlock>

              <RecipientUploadWarningText>
                <span dangerouslySetInnerHTML={{ __html: translate('multiLocationTermsWarningText') }} />
              </RecipientUploadWarningText>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
);

MultiLocation.propTypes = {
  orderRecipientCsv: object.isRequired,
  translate: func.isRequired,
};

export default withI18n(MultiLocation);
