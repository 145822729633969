import Immutable from 'immutable';

import * as actionTypes from '../constants/orderConstants';
import * as draftOrderActionTypes from '../constants/draftOrderConstants';

const ACTIVE_TAB = 'basket';
const ORDER_ACTIVE_VIEW = actionTypes.ORDER_SUMMARY_VIEW;

export const $$initialState = Immutable.fromJS({
  activeTab: ACTIVE_TAB,
  order: {},
  orderActiveView: ORDER_ACTIVE_VIEW,
  lastAction: null, // 'OrderConfirmed', 'OrderDeclined', 'ChangesConfirmed', 'ChangesDeclined'
  errors: null,
});

// Reset the state
function reset(state) {
  return state
    .set('errors', null)
    .set('lastAction', null);
}

export default function orderReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.CHANGE_ORDER_TAB: {
      return reset($$state).set('activeTab', response);
    }

    // CONFIRM ORDER
    case actionTypes.CONFIRMING_ORDER: {
      return reset($$state);
    }
    case actionTypes.CONFIRM_ORDER_SUCCESS: {
      return reset($$state)
        .set('lastAction', 'OrderConfirmed')
        .setIn(['order', 'status'], response.status);
    }
    case actionTypes.CONFIRM_ORDER_FAILURE: {
      return reset($$state)
        .set('errors', response.errors);
    }

    // CONFIRM ORDER CHANGES
    case actionTypes.CONFIRMING_ORDER_CHANGES: {
      return reset($$state);
    }
    case actionTypes.CONFIRM_ORDER_CHANGES_SUCCESS: {
      return reset($$state)
        .set('lastAction', 'ChangesConfirmed')
        .setIn(['order', 'status'], response.status);
    }
    case actionTypes.CONFIRM_ORDER_CHANGES_FAILURE: {
      return reset($$state)
        .set('errors', response.errors);
    }

    // DECLINE ORDER
    case actionTypes.DECLINING_ORDER: {
      return reset($$state);
    }
    case actionTypes.DECLINE_ORDER_SUCCESS: {
      return reset($$state)
        .set('lastAction', 'OrderDeclined')
        .setIn(['order', 'status'], response.status);
    }
    case actionTypes.DECLINE_ORDER_FAILURE: {
      return reset($$state)
        .set('errors', response.errors);
    }
    // DECLINE ORDER CHANGES
    case actionTypes.DECLINING_ORDER_CHANGES: {
      return reset($$state);
    }
    case actionTypes.DECLINE_ORDER_CHANGES_SUCCESS: {
      return reset($$state)
        .set('lastAction', 'ChangesDeclined')
        .setIn(['order', 'status'], response.status);
    }
    case actionTypes.DECLINE_ORDER_CHANGES_FAILURE: {
      return reset($$state)
        .set('errors', response.errors);
    }

    // CONFIRM CANCELLATION_REQUEST
    case actionTypes.CONFIRMING_CANCELLATION_REQUEST: {
      return reset($$state);
    }
    case actionTypes.CONFIRM_CANCELLATION_REQUEST_SUCCESS: {
      return reset($$state)
        .set('lastAction', 'CancelledByCustomer')
        .setIn(['order', 'status'], response.status);
    }
    case actionTypes.CONFIRM_CANCELLATION_REQUEST_FAILURE: {
      return reset($$state)
        .set('errors', response.errors);
    }

    // ORDER VIEWS
    case actionTypes.SELECT_ORDER_VIEW: {
      return $$state.withMutations(state => (
        state
          .set('orderActiveView', response.orderActiveView)
      ));
    }

    case draftOrderActionTypes.FETCH_DRAFT_ORDER_SUCCESS: {
      const { order } = response;

      return $$state.withMutations(state => (
        state
          .set('order', Immutable.fromJS(order))
      ));
    }

    default: {
      return $$state;
    }
  }
}
