export const CHANGE_ORDER_TAB = 'CHANGE_ORDER_TAB';
export const SHOW_ORDER_HISTORY = 'SHOW_ORDER_HISTORY';
export const HIDE_ORDER_HISTORY = 'HIDE_ORDER_HISTORY';

// Confirm Order
export const CONFIRMING_ORDER = 'CONFIRMING_ORDER';
export const CONFIRM_ORDER_SUCCESS = 'CONFIRM_ORDER_SUCCESS';
export const CONFIRM_ORDER_FAILURE = 'CONFIRM_ORDER_FAILURE';

// Decline Order
export const DECLINING_ORDER = 'DECLINING_ORDER';
export const DECLINE_ORDER_SUCCESS = 'DECLINE_ORDER_SUCCESS';
export const DECLINE_ORDER_FAILURE = 'DECLINE_ORDER_FAILURE';

// Confirm Order
export const CONFIRMING_ORDER_CHANGES = 'CONFIRMING_ORDER_CHANGES';
export const CONFIRM_ORDER_CHANGES_SUCCESS = 'CONFIRM_ORDER_CHANGES_SUCCESS';
export const CONFIRM_ORDER_CHANGES_FAILURE = 'CONFIRM_ORDER_CHANGES_FAILURE';

// Decline Order
export const DECLINING_ORDER_CHANGES = 'DECLINING_ORDER_CHANGES';
export const DECLINE_ORDER_CHANGES_SUCCESS = 'DECLINE_ORDER_CHANGES_SUCCESS';
export const DECLINE_ORDER_CHANGES_FAILURE = 'DECLINE_ORDER_CHANGES_FAILURE';

// Confirm Order Cancellation
export const CONFIRMING_CANCELLATION_REQUEST = 'CONFIRMING_CANCELLATION_REQUEST';
export const CONFIRM_CANCELLATION_REQUEST_SUCCESS = 'CONFIRM_CANCELLATION_REQUEST_SUCCESS';
export const CONFIRM_CANCELLATION_REQUEST_FAILURE = 'CONFIRM_CANCELLATION_REQUEST_FAILURE';

export const SELECT_ORDER_VIEW = 'SELECT_ORDER_VIEW';

export const ORDER_DETAILS_VIEW = 'order_details';
export const ORDER_SUMMARY_VIEW = 'order_summary';
