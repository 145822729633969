import { useQuery } from 'react-query';
import { httpClient } from 'libs/support/http-client';

export const useDashboardPerformance = (params) => (
  useQuery([
    'DASHBOARD_PERFORMANCE',
    Object.values(params),
  ], async () => {
    const { data } = await httpClient.get('/v2/dashboards/performance.json', {
      params,
    });

    return data;
  }, {
    staleTime: Infinity,
  })
);
