import React, { useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as orderVendorStatementActions from 'bundles/orders/actions/orderVendorStatementActions';
import { ExternalIcon } from 'libs/components/Icons';
import {
  SecondaryFont,
  PrimaryBadge,
  SecondaryBadge,
} from 'libs/support/sharedComponents';
import { sanitizedEmails } from 'libs/support/email';

const VendorStatement = ({
  isFinding,
  isFindSuccess,
  findOrderVendorStatement,
  vendorStatement,
  orderId,
  translate,
}) => {
  useEffect(() => {
    if (!isFindSuccess && !isFinding) {
      findOrderVendorStatement(orderId);
    }
  }, [isFindSuccess, isFinding]);

  if (!isFindSuccess || vendorStatement.size === 0) return null;

  return (
    <div id="order-vendor-statement">
      <div className="content-box">
        <strong className="mr-1">
          { translate('orderVendorStatementsTitlesVendorPayment') }
        </strong>

        {
          !vendorStatement.getIn(['file', 'url']) &&
            <SecondaryBadge>
              { translate('orderVendorStatementsStatusesNotSent') }
            </SecondaryBadge>
        }

        {
          vendorStatement.getIn(['file', 'url']) &&
            <PrimaryBadge>
              { translate('orderVendorStatementsStatusesSent') }
            </PrimaryBadge>
        }

        {
          vendorStatement.getIn(['file', 'url']) &&
            <div className="mt-1">
              <a
                href={`/orders/${orderId}/vendor_statement_for_orders/download_statement`}
                target="_blank"
                rel="noopener noreferrer"
              >
                { `V-INV-${vendorStatement.getIn(['data', 'reference_number'])}.pdf` }

                <span className="ml-1">
                  <ExternalIcon style={{ marginBottom: '-1px' }} />
                </span>
              </a>
            </div>
        }

        {
          vendorStatement.getIn(['file', 'url']) &&
            <SecondaryFont as="div">
              {translate('orderVendorStatementsSentTo')}:&nbsp;

              { sanitizedEmails(vendorStatement.getIn(['data', 'billable', 'emails'])) }
            </SecondaryFont>
        }
      </div>
    </div>
  );
};

VendorStatement.propTypes = {
  isFinding: bool,
  isFindSuccess: bool,
  findOrderVendorStatement: func.isRequired,
  vendorStatement: object,
  orderId: string,
  translate: func.isRequired,
};

const mapStateToProps = ({
  $$orderStore,
  $$orderVendorStatementStore,
}) => ({
  orderId: $$orderStore.getIn(['order', 'uuid']),
  isFinding: $$orderVendorStatementStore.get('isFinding'),
  isFindSuccess: $$orderVendorStatementStore.get('isFindSuccess'),
  vendorStatement: $$orderVendorStatementStore.get('vendorStatement'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  findOrderVendorStatement: orderVendorStatementActions.findOrderVendorStatement,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withI18n(VendorStatement));
