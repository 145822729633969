import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import uppercamelcase from 'uppercamelcase';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { defaultMessages } from 'libs/i18n/default';

import Tab from './Tab/Tab';

const TABS_V2 = ['basket', 'customer', 'vendor', 'order_history', 'billing'];

class OrderTabs extends BaseComponent {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onChangeTab: PropTypes.func.isRequired,
    orderDeliveryType: PropTypes.string.isRequired,
    tabs: PropTypes.array,
    vendorKind: PropTypes.string.isRequired,
  };

  static defaultProps = {
    tabs: TABS_V2,
  }

  rejectCustomerTab = () => (
    this.props.tabs.filter((v) => (v !== 'customer'))
  )

  supportedTabs = () => {
    const { orderDeliveryType, tabs, vendorKind } = this.props;

    const includeCustomerTab = orderDeliveryType === 'caterspot' &&
      vendorKind !== 'activities';

    return (
      includeCustomerTab
        ? this.rejectCustomerTab()
        : tabs
    );
  }

  render() {
    const { activeTab, intl } = this.props;
    const { formatMessage } = intl;

    return (
      <div className="row">
        <div className="col-md-12">
          <div id="secondary-nav">
            <nav className="navbar navbar-expand-md navbar-light">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#OrderNavExpand"
                aria-controls="OrderNavExpand"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="dropdown-title">
                  {formatMessage(defaultMessages[`ordersShowTabs${uppercamelcase(activeTab)}`])}
                  <span className="fa fa-caret-down pull-right mr-1" />
                </span>
              </button>

              <div className="navbar-collapse collapse" id="OrderNavExpand">
                <ul className="nav navbar-nav">
                  {
                    map(this.supportedTabs(), tab => (
                      <Tab
                        key={`order-tab-${tab}`}
                        tab={tab}
                        {...this.props}
                      />
                    ))
                  }
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(OrderTabs);
