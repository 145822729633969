import React, { Fragment } from 'react';
import { object, number, string } from 'prop-types';

import { blankish } from 'libs/support/string';

import PaymentField from '../OrderVariation/PaymentField/PaymentField';
import DynamicField from '../OrderVariation/DynamicField/DynamicField';
import TotalQuantity from '../TotalQuantity';

/**
 * AmountRows
 */

const AmountRows = ({
  subtotalAmount,
  vendorRefund,
  surchargeAmount,
  deliveryAmount,
  totalAmount,
  taxAmount,
  deliveryType,
  catererTaxType,
  customMenuItems,
  orderVariations,
  surchargeName,
}) => (
  <Fragment>
    <PaymentField
      name="Subtotal"
      className="subtotal-details"
      value={subtotalAmount}
    >
      <TotalQuantity
        {
          ...{
            customMenuItems,
            orderVariations,
          }
        }
      />
    </PaymentField>

    {
      !blankish(vendorRefund) &&
      <DynamicField
        key="dynamic-field-vendor_refund"
        field="vendor_refund"
        value={vendorRefund}
      />
    }

    {/* OWN Delivery */}
    {
      deliveryType === 'own' &&
      <Fragment>
        <DynamicField
          field="delivery_amount"
          value={deliveryAmount}
        />

        <PaymentField
          name="Surcharge"
          otherName={surchargeName}
          value={surchargeAmount}
        />
      </Fragment>
    }

    {
      catererTaxType === 'exclusive' &&
      <PaymentField
        name="Gst"
        value={taxAmount}
      />
    }

    <PaymentField
      isStrong
      name="Total"
      className="total"
      value={totalAmount}
    />

    {
      catererTaxType === 'inclusive' &&
      <PaymentField
        name="InclusiveOfGst"
        value={taxAmount}
      />
    }
  </Fragment>
);

AmountRows.propTypes = {
  subtotalAmount: number,
  vendorRefund: number,
  surchargeAmount: number,
  deliveryAmount: number,
  totalAmount: number,
  taxAmount: number,
  surchargeName: string,
  deliveryType: string.isRequired,
  catererTaxType: string.isRequired,
  customMenuItems: object,
  orderVariations: object,
};

export default AmountRows;
