/* eslint react/no-danger: 0 */

import React from 'react';
import { func, object } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';

const getMessageKey = (orderableSettingStore) => (
  `orderableSettingsCutleryMessageHtml${uppercamelcase(orderableSettingStore.get('cutleryEnabled').toString())}`
);

const CutleryMessage = ({
  orderableSettingStore,
  translate,
}) => (
  <span
    dangerouslySetInnerHTML={
      {
        __html: translate(getMessageKey(orderableSettingStore)),
      }
    }
  />
);

CutleryMessage.propTypes = {
  orderableSettingStore: object.isRequired,
  translate: func.isRequired,
};

export default withI18n(CutleryMessage);
