import React from 'react';
import LargeBasket from 'libs/components/Icons/v2/LargeBasket';
import { withI18n } from 'libs/support/i18n';
import { object, func, array, number, string } from 'prop-types';
import BasketBody from './BasketBody';

const OrderCarts = ({
  order,
  totalQuantity,
  orderableType,
  orderableVariations,
  translate,
  customMenuItems,
}) => (
  <div className="order-details-basket-box order-details-basket-box-carts-desktop">
    <div className="order-details-basket-box-header">
      <LargeBasket />
      <span className="order-details-basket-text-header">{translate('ordersShowDetailsOrderCartsBasket')}</span>
    </div>
    <div className="basket-header">
      <span className="basket-header-qty">Qty</span>
      <span className="basket-header-item">Item</span>
      { order.groupOrder &&
        <span className="basket-header-label">Label</span>
      }
      <span className="basket-header-price">Price</span>
    </div>
    <div className="basket-body">
      <BasketBody
        {
            ...{
              order,
              orderableType,
              orderableVariations,
              customMenuItems,
              totalQuantity,
            }
          }
      />
    </div>
  </div>
);

OrderCarts.propTypes = {
  order: object,
  totalQuantity: number,
  orderableType: string,
  orderableVariations: array,
  translate: func,
  customMenuItems: array,
};

export default withI18n(OrderCarts);
