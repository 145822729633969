/* eslint max-len: 0 */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { string, object, func } from 'prop-types';
import soundfile from 'assets/audios/notification-sound.wav';
import { useOrder, useOrderBilling, useAcceptOrder, useDeclineOrder, useAcceptChangesOrder, useDeclineChangesOrder, useOrderHistories, useConfirmOrderReminder, useUpdateOrderDriver } from 'bundles/v2/orders/queries/orders';
import { useCart } from 'bundles/v2/orders/queries/carts';
import { withAppContext } from 'libs/support/appContext';
import { withI18n } from 'libs/support/i18n';
import OrderDeclineModal from 'bundles/orders/components/Order/OrderDeclineModal';
import OrderAcceptModal from 'bundles/orders/components/Order/OrderAcceptModal';

import DesktopOrderDetails from './DesktopOrderDetails';

const CHANNEL = 'cartChannel';

const OrderDetails = ({
  orderId,
  isDraftOrder,
  cable,
  translate,
}) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showMoreBilling, setShowMoreBilling] = useState(false);
  const [showMoreHistory, setShowMoreHistory] = useState(false);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [onShowDeclineModal, setOnShowDeclineModal] = useState(false);
  const [onShowAcceptModal, setOnShowAcceptModal] = useState(false);
  const audio = new Audio(soundfile);
  const channel = useRef(null);

  const playSound = (volume) => {
    audio.volume = volume;
    audio.play().catch(() => {

    });
  };

  const { data: order, refetch: refetchOrder, isLoading } = useOrder({ orderableId: orderId, isDraftOrder });
  const { data: cart, isLoading: isCartLoading, refetch } = useCart({ orderableId: orderId, isDraftOrder });

  const { data: orderBilling, isLoading: isOrderBillingLoading } = useOrderBilling({ orderId, isDraftOrder }); // eslint-disable-line

  const { data: orderHistories, isLoading: isOrderHistoriesLoading } = useOrderHistories({ orderId, isDraftOrder }); // eslint-disable-line

  const {
    mutateAsync: acceptOrder,
    isLoading: isAcceptOrderLoading, // eslint-disable-line
    isSuccess: isAcceptOrderSuccess,
    error: acceptOrderError, // eslint-disable-line
  } = useAcceptOrder();

  const {
    mutateAsync: declineOrder,
    isLoading: isDeclineOrderLoading, // eslint-disable-line
    isSuccess: isDeclineOrderSuccess,
    error: declineOrderError, // eslint-disable-line
  } = useDeclineOrder();

  const { mutateAsync: acceptChangesOrder, isSuccess: isAcceptChangesOrderSuccess } = useAcceptChangesOrder();
  const { mutateAsync: declineChangesOrder, isSuccess: isDeclineChangesOrderSuccess } = useDeclineChangesOrder();
  const { mutateAsync: confirmOrderReminder, isSuccess: isConfirmOrderReminderSuccess } = useConfirmOrderReminder();
  const { mutateAsync: updateOrderDriver } = useUpdateOrderDriver(orderId);

  const subscribeChannel = useCallback(() => {
    channel.current = cable.subscribe({
      channel: CHANNEL,
      params: { cartId: orderId },
      connectedCallback: () => {
        setErrorMsg(null);
      },
      disconnectedCallback: () => {
        setErrorMsg(translate('draftOrdersShowSocketError'));
      },
      receivedCallback: (data) => {
        if (!data) return;
        const { type } = data;
        if (type === 'creation') {
          playSound(1);
          refetch();
        }
      },
    });
  }, []);

  useEffect(() => {
    if (isDraftOrder) { playSound(0.01); }
    if (!channel.current && isDraftOrder) subscribeChannel();

    return function cleanup() { // eslint-disable-line
      if (channel.current) { return cable.unsubscribe(channel.current); }
    };
  }, []);

  if (isLoading || isCartLoading) {
    return <div className="loader-container mt-2"><span className="loader" /></div>;
  }

  const orderable = order || cart;
  const orderableType = isDraftOrder ? 'cart' : 'order';

  const postAction = () => {
    setTimeout(() => {
      setIsLoadingAction(false);
      setTimeout(() => {
        refetchOrder();
      }, 600);
    }, 600);
  };

  const driverParams = (params) => ({
    driverName: params.get('driver_name'),
    driverPhoneNumber: params.get('driver_phone_number'),
  });

  const runAction = async (action, params) => { // eslint-disable-line
    if (action === 'accept') {
      if (params.has('driver_name')) {
        try {
          await updateOrderDriver(driverParams(params));
        } catch (error) {
          setErrorMsg(error);
        }
        return acceptOrder({ skip_reminder: true, order_id: orderId });
      }
      return acceptOrder({ order_id: orderId });
    }
    if (action === 'accept_changes') {
      if (params.has('driver_name')) {
        try {
          await updateOrderDriver(driverParams(params));
        } catch (error) {
          setErrorMsg(error);
        }
        return acceptChangesOrder({ skip_reminder: true, order_id: orderId });
      }
      return acceptChangesOrder({ order_id: orderId });
    }
    if (action === 'decline_changes') {
      return declineChangesOrder(orderId);
    }
    if (action === 'decline') {
      const body = {
        decline_reasons: params.decline_reasons,
        decline_detail: params.decline_detail,
        order_id: orderId,
      };
      return declineOrder(body);
    }
    if (action === 'confirm_order_reminder') {
      return confirmOrderReminder(orderId);
    }
  };

  const onChangeAction = (e, action, params = {}) => {
    if (e) { e.preventDefault(); }
    setIsLoadingAction(true);
    runAction(action, params).then(() => {
      postAction();
    });
  };

  const showDeclineModal = (e) => {
    e.preventDefault();
    setOnShowDeclineModal(true);
  };

  const showAcceptModal = (e) => {
    e.preventDefault();

    const oneDayAfter = new Date();
    oneDayAfter.setDate(oneDayAfter.getDate() + 1);
    const deliverAtCuttOff = new Date(orderable.deliverAt).setHours(0, 0, 0, 0);

    if (orderable.deliveryType === 'own' && oneDayAfter > deliverAtCuttOff) {
      setOnShowAcceptModal(true);
    } else {
      onChangeAction(null, 'accept', new FormData());
    }
  };

  return (
    <div>
      <DesktopOrderDetails
        {
          ...{
            orderId,
            isDraftOrder,
            orderable,
            orderableType,
            showMoreBilling,
            setShowMoreBilling,
            showMoreHistory,
            setShowMoreHistory,
            orderBilling,
            errorMsg,
            onChangeAction,
            isLoadingAction,
            isAcceptOrderSuccess,
            isDeclineOrderSuccess,
            setOnShowDeclineModal,
            showDeclineModal,
            showAcceptModal,
            isAcceptChangesOrderSuccess,
            isDeclineChangesOrderSuccess,
            isConfirmOrderReminderSuccess,
            orderHistories,
          }
        }
      />
      <OrderDeclineModal
        show={onShowDeclineModal}
        onHide={() => setOnShowDeclineModal(false)}
        onSubmit={(params) => onChangeAction(null, 'decline', params)}
      />
      <OrderAcceptModal
        show={onShowAcceptModal}
        onHide={() => setOnShowAcceptModal(false)}
        onSubmit={(params) => onChangeAction(null, 'accept', params)}
        driver={orderable.drivers ? orderable.drivers[0] : null}
        translate={translate}
      />
    </div>
  );
};

OrderDetails.propTypes = {
  orderId: string.isRequired,
  isDraftOrder: string,
  cable: object.isRequired,
  translate: func,
};

export default withAppContext(withI18n(OrderDetails));
