import React from 'react';
import { withI18n } from 'libs/support/i18n';
import { object, func, array, string } from 'prop-types';
import BasketBody from './BasketBody';

const MobileOrderCarts = ({
  order,
  orderableType,
  orderableVariations,
  translate,
  customMenuItems,
}) => (
  <div className="order-details-basket-box order-details-basket-box-carts-mobile">
    <div className="order-details-basket-box-header">
      <span className="order-details-basket-text-header">{translate('ordersShowDetailsOrderCartsBasket')}</span>
    </div>
    <div className="basket-body">
      <BasketBody
        {
            ...{
              order,
              orderableType,
              orderableVariations,
              customMenuItems,
              mobile: true,
            }
          }
      />
    </div>
  </div>
);

MobileOrderCarts.propTypes = {
  order: object,
  orderableType: string,
  orderableVariations: array,
  translate: func,
  customMenuItems: array,
};

export default withI18n(MobileOrderCarts);
