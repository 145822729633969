/* eslint max-len: 0 */

import messagesReducer, { $$initialState as $$messagesState } from 'bundles/common/reducers/messagesReducer';
import orderReducer, { $$initialState as $$orderState } from './orderReducer';
import orderBillingReducer, { $$initialState as $$orderBillingState } from './orderBillingReducer';
import orderVendorStatementReducer, { $$initialState as $$orderVendorStatementState } from './orderVendorStatementReducer';
import userReducer, { $$initialState as $$userState } from './userReducer';
import catererReducer, { $$initialState as $$catererState } from './catererReducer';
import orderVariationsReducer, { $$initialState as $$orderVariationsState } from './orderVariationsReducer';
import customMenuItemsReducer, { $$initialState as $$customMenuItemsState } from './customMenuItemsReducer';
import menuItemsReducer, { $$initialState as $$menuItemsState } from './menuItemsReducer';
import orderHistoriesReducer, { $$initialState as $$orderHistoriesState } from './orderHistoriesReducer';
import localeReducer, { $$initialState as $$localeState } from '../../common/reducers/localeReducer';
import vendorsReducer, { $$initialState as $$vendorsState } from '../../common/reducers/vendorsReducer';

export default {
  $$orderStore: orderReducer,
  $$orderBillingStore: orderBillingReducer,
  $$orderVendorStatementStore: orderVendorStatementReducer,
  $$userStore: userReducer,
  $$catererStore: catererReducer,
  $$menuItemsStore: menuItemsReducer,
  $$orderVariationsStore: orderVariationsReducer,
  $$customMenuItemsStore: customMenuItemsReducer,
  $$orderHistoriesStore: orderHistoriesReducer,
  $$localeStore: localeReducer,
  $$orderableSettingStore: ($$state = {}) => ($$state),
  $$vendorsStore: vendorsReducer,
  $$messagesStore: messagesReducer,
};

export const initialStates = {
  $$orderState,
  $$orderBillingState,
  $$orderVendorStatementState,
  $$userState,
  $$catererState,
  $$menuItemsState,
  $$orderVariationsState,
  $$customMenuItemsState,
  $$orderHistoriesState,
  $$localeState,
  $$vendorsState,
  $$messagesState,
};
