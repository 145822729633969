import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/draftOrderConstants';

export function fetchDraftOrder(orderId, params = {}) {
  const types = [
    actionTypes.FETCHING_DRAFT_ORDER,
    actionTypes.FETCH_DRAFT_ORDER_SUCCESS,
    actionTypes.FETCH_DRAFT_ORDER_FAILURE,
  ];

  return api.get({
    types,
    endpoint: `/draft_orders/${orderId}`,
    params,
  });
}
