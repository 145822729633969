export const currencyFormat = (value, opts = {}) => {
  const { symbol } = opts;

  if (value === undefined || value === null || value.toString().trim() === '') {
    return null;
  }

  if (symbol) { return `${symbol}${(Math.round(value * 100) / 100).toFixed(2)}`; }
  return (Math.round(value * 100) / 100).toFixed(2);
};
