import Immutable from 'immutable';

export const $$initialState = Immutable.fromJS({
  caterer: {},
});

export default function catererReducer($$state = $$initialState, action = null) {
  const { type } = action;

  switch (type) {
    default: {
      return $$state;
    }
  }
}
