import React from 'react';
import { any, bool, object, string } from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { notBlank } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

class OrderBasketOrderVariationPaymentField extends BaseComponent {
  static propTypes = {
    isStrong: bool,
    name: string.isRequired,
    otherName: string,
    className: string,
    value: any,
    children: object,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    isStrong: false,
  };

  render() {
    const {
      children,
      className,
      intl,
      isStrong,
      name,
      otherName,
      value,
    } = this.props;
    const { formatMessage } = intl;

    let title = formatMessage(defaultMessages[`ordersShowFormsOrderVariationsTerms${name}`]);

    if (notBlank(otherName)) title = `${title} ${otherName}`;

    return (
      <tr className={`total-details ${className}`}>
        <td colSpan="2">
          { children }
        </td>

        <td colSpan="2" className="dotted">
          {
            (isStrong) ?
              <strong>{title}</strong> :
              title
          }
        </td>

        <td className="dotted">
          {
            (isStrong) ?
              <strong>{value}</strong> :
              value
          }
        </td>
      </tr>
    );
  }
}

export default injectIntl(OrderBasketOrderVariationPaymentField);
