/* eslint max-len: 0 */
/* eslint no-unused-expressions: 0 */

import React from 'react';
import { func, string } from 'prop-types';
import { withI18n } from 'libs/support/i18n';

import BaseComponent from 'libs/components/BaseComponent';
import LocationContainer from 'bundles/orders/containers/LocationContainer';

const TAB = 'customer';

class OrderCustomerForm extends BaseComponent {
  static propTypes = {
    activeTab: string.isRequired,
    orderDeliveryType: string.isRequired,
    email: string,
    firstName: string.isRequired,
    lastName: string,
    mobile: string.isRequired,
    translate: func.isRequired,
    vendorKind: string.isRequired,
  };

  render() {
    const {
      activeTab,
      email,
      firstName,
      mobile,
      translate,
      vendorKind,
    } = this.props;

    if (activeTab !== TAB) return null;

    return (
      <div>
        <div className="group-actions">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-title">
                <h6>
                  {translate('ordersShowFormsCustomerCustomerInformation')}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="content-box">
          <form>
            <div className="form-group row">
              <label className="col-sm-3 form-control-label">
                {translate('ordersShowFormsCustomerCustomerProfile')}
              </label>

              <div className="col-sm-9" />
            </div>

            <fieldset className="contact-info">
              <div className="form-group row">
                <label className="col-sm-3 form-control-label">
                  {translate('ordersShowFormsCustomerFieldsFirstName')}
                </label>

                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="user[first_name]"
                    defaultValue={firstName}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-3 form-control-label">
                  {translate('ordersShowFormsCustomerFieldsPhone')}
                </label>

                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="user[mobile]"
                    defaultValue={mobile}
                  />
                </div>
              </div>

              {
                vendorKind === 'activities' &&
                  <div className="form-group row">
                    <label className="col-sm-3 form-control-label">
                      {translate('ordersShowFormsCustomerFieldsEmail')}
                    </label>

                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        name="user[email]"
                        defaultValue={email}
                      />
                    </div>
                  </div>
              }
            </fieldset>
          </form>
        </div>

        <LocationContainer />
      </div>
    );
  }
}

export default withI18n(OrderCustomerForm);
