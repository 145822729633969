import React from 'react';
import { string, func } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';
import * as orderActions from 'bundles/orders/actions/orderActions';
import Header from 'bundles/orders/components/Order/Header';

const OrderItemsHeaderContainer = ({
  orderActiveView,
  selectOrderView,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Header
        {
          ...{
            orderActiveView,
            selectOrderView,
          }
        }
      />
    </IntlProvider>
  );
};

OrderItemsHeaderContainer.propTypes = {
  orderActiveView: string.isRequired,
  selectOrderView: func.isRequired,
};

const mapStateToProps = (state) => {
  const orderStore = state.$$orderStore;

  return {
    orderActiveView: orderStore.get('orderActiveView'),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  selectOrderView: orderActions.selectOrderView,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderItemsHeaderContainer);
