import React from 'react';
import { string, func } from 'prop-types';
import { withI18n } from 'libs/support/i18n';
import uppercamelcase from 'uppercamelcase';

import {
  ORDER_DETAILS_VIEW,
  ORDER_SUMMARY_VIEW,
} from 'bundles/orders/constants/orderConstants';

const OPTIONS = [ORDER_DETAILS_VIEW, ORDER_SUMMARY_VIEW];

const handleChange = ({ selectOrderView }) => (e) => {
  const { value } = e.target;

  selectOrderView(value);
};

const Selection = ({
  translate,
  orderActiveView,
  selectOrderView,
}) => (
  <div className="order-items-header__selection">
    <label>
      {translate('ordersShowFormsOrderView')}:
    </label>

    <select
      className="custom-select form-control-sm"
      name="order[order_view]"
      defaultValue={orderActiveView}
      onChange={handleChange({ selectOrderView })}
    >
      {
        OPTIONS.map(value => (
          <option
            key={`state-${value}`}
            value={value}
          >
            {translate(`ordersShowFormsOrderViews${uppercamelcase(value)}`)}
          </option>

        ))
      }
    </select>
  </div>
);

Selection.propTypes = {
  orderActiveView: string.isRequired,
  translate: func.isRequired,
  selectOrderView: func.isRequired,
};

export default withI18n(Selection);
