/* eslint max-len: 0 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import moment from 'moment';

import BaseComponent from 'libs/components/BaseComponent';
import { withI18n } from 'libs/support/i18n';
import { blankish, pascalCase } from 'libs/support/string';
import { DATE_DISPLAY_FORMAT, TIME_DISPLAY_FORMAT } from 'libs/constants/date';
import { DELIVERY_TYPE_CATERSPOT } from 'libs/constants/vendor';

class OrderAside extends BaseComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    catererName: PropTypes.string.isRequired,
    isDraftOrder: PropTypes.bool,
    orderDeliveryType: PropTypes.string,
    orderCreatedAt: PropTypes.string,
    orderDeliveryDate: PropTypes.string,
    orderEndDeliveryDate: PropTypes.string,
    orderPickupDate: PropTypes.string,
    orderStatus: PropTypes.string,
    translate: PropTypes.func.isRequired,
    vertical: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isDraftOrder: false,
  }

  renderPickUpSection() {
    const { orderDeliveryDate, orderPickupDate, translate } = this.props;

    let finalOrderPickupDate = moment(orderPickupDate);

    if (blankish(orderPickupDate)) finalOrderPickupDate = moment(orderDeliveryDate).subtract(60, 'minutes');

    return (
      <div className="group-block">
        <div className="sidebar-heading">
          {translate('ordersShowFormsPickupAtTitle')}
        </div>

        <p>{finalOrderPickupDate.utc().format(DATE_DISPLAY_FORMAT)}</p>
      </div>
    );
  }

  renderDeliveryAtSection({ vertical }) {
    const { orderDeliveryDate, orderEndDeliveryDate, translate } = this.props;
    const finalTitle = translate(`ordersShowFormsDeliverAtTitle${pascalCase(vertical)}`);

    return (
      <div className="group-block">
        <div className="sidebar-heading">
          {finalTitle}
        </div>

        <p>
          {moment(orderDeliveryDate).utc().format(DATE_DISPLAY_FORMAT)}
          {
            orderEndDeliveryDate &&
            ` - ${moment(orderEndDeliveryDate).utc().format(TIME_DISPLAY_FORMAT)}`
          }
        </p>
      </div>
    );
  }

  render() {
    const {
      catererName,
      name,
      isDraftOrder,
      orderCreatedAt,
      orderDeliveryType,
      orderStatus,
      translate,
      vertical,
    } = this.props;

    return (
      <aside>
        <div className="customer-info">
          {orderDeliveryType !== 'caterspot' && (
            <div className="group-block">
              <div className="sidebar-heading">
                {translate('ordersShowTermsCustomerName')}
              </div>

              <div className="media">
                <div className="media-body">
                  <span>{name}</span>
                </div>
              </div>
            </div>
          )}

          <div className="group-block">
            <div className="sidebar-heading">
              {translate('ordersShowFormsStatusTitle')}
            </div>

            <div className="media">
              <div className="media-body">
                {translate(`ordersStates${uppercamelcase(orderStatus)}`)}
              </div>
            </div>
          </div>

          <div className="group-block">
            <div className="sidebar-heading">
              {translate('ordersShowTermsVendorName')}
            </div>

            <p>{catererName}</p>
          </div>

          {
            !isDraftOrder &&
              <div className="group-block">
                <div className="sidebar-heading">
                  {translate('ordersShowTermsOrderDateAndTime')}
                </div>

                <p>{moment(orderCreatedAt).format(DATE_DISPLAY_FORMAT)}</p>
              </div>
          }

          { /* Activities */ }
          {
            vertical === 'activities' &&
            <Fragment>
              {
                (orderDeliveryType !== DELIVERY_TYPE_CATERSPOT) ?
                this.renderDeliveryAtSection({ vertical }) :
                this.renderPickUpSection()
              }

              <div className="group-block">
                <div className="sidebar-heading">
                  {translate('ordersShowFormsDeliveryTypeTitle')}
                </div>

                <p>{translate(`ordersShowFormsDeliveryTypeOptions${uppercamelcase(orderDeliveryType)}`)}</p>
              </div>
            </Fragment>
          }

          { /* The rest of the verticals */ }
          {
            vertical !== 'activities' &&
            <Fragment>
              <div className="group-block">
                <div className="sidebar-heading">
                  {translate('ordersShowFormsDeliveryTypeTitle')}
                </div>

                <p>{translate(`ordersShowFormsDeliveryTypeOptions${uppercamelcase(orderDeliveryType)}`)}</p>
              </div>

              {
                (orderDeliveryType !== DELIVERY_TYPE_CATERSPOT) ?
                this.renderDeliveryAtSection({ vertical: '' }) :
                this.renderPickUpSection()
              }
            </Fragment>
          }
        </div>
      </aside>
    );
  }
}

export default withI18n(OrderAside);
