import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { notBlank } from 'libs/support/string';

const OptionAdditionalInfo = ({
  choice,
}) => {
  const photo = choice.getIn(['photos', 0]);
  const text = choice.get('text');

  return (
    <Fragment>
      {
        notBlank(photo) && notBlank(photo.get('url')) &&
          <Fragment>
            : { photo.get('url').split('/').pop() }

            <br />

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={photo.get('url')}
            >
              Download File
            </a>
          </Fragment>
      }

      {
        notBlank(text) &&
          <Fragment>
            <span>: </span>
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {text}
            </span>
          </Fragment>
      }
    </Fragment>
  );
};

OptionAdditionalInfo.propTypes = {
  choice: object.isRequired,
};

export default OptionAdditionalInfo;
