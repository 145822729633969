import React from 'react';
import PropTypes from 'prop-types';
import uppercamelcase from 'uppercamelcase';
import { injectIntl, intlShape } from 'react-intl';

import BaseComponent from 'libs/components/BaseComponent';
import { defaultMessages } from 'libs/i18n/default';

class OrderVariationDynamicField extends BaseComponent {
  static propTypes = {
    value: PropTypes.number,
    field: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const { value, field, intl } = this.props;
    const { formatMessage } = intl;

    return (
      <tr className="total-details">
        <td colSpan="2" />

        <td colSpan="2" className="dotted">
          {formatMessage(defaultMessages[`ordersShowFormsOrderVariationsTerms${uppercamelcase(field)}`])}
        </td>

        <td className="dotted">{value}</td>
      </tr>
    );
  }
}

export default injectIntl(OrderVariationDynamicField);
