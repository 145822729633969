import React from 'react';
import { string, func } from 'prop-types';
import renderHTML from 'react-render-html';
import { withI18n } from 'libs/support/i18n';
import { blankish } from 'libs/support/string';

const ConsolidatedOrderVariationInstructions = ({
  className,
  value,
  translate,
}) => {
  if (blankish(value)) return null;

  return (
    <tr className={`order-variation-additional-instructions ${className}`}>
      <td />
      <td className="no-borders p-b-15">
        {translate('ordersShowFormsOrderVariationsTermsAdditionalInstructions')}:
        <br />
        {renderHTML(value)}
      </td>
    </tr>
  );
};

ConsolidatedOrderVariationInstructions.propTypes = {
  className: string.isRequired,
  value: string,
  translate: func.isRequired,
};

export default withI18n(ConsolidatedOrderVariationInstructions);
