import React from 'react';
import { func, object, string } from 'prop-types';
import map from 'lodash/map';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import Header from './Header';

const FIELDS = [
  'street_address',
  'unit_address',
  'postcode',
  'city_full_name',
  'additional_instructions',
];
const ADDITIONAL_INSTRUCTIONS = 'additional_instructions';

const InOfficeActivity = ({
  address,
  additionalInstructions,
  translate,
}) => (
  <div className="event-location">
    <Header />
    <div className="content-box">
      <div className="form-group row">
        <label className="col-sm-3 form-control-label">
          {translate('eventLocationLabelsLocation')}
        </label>

        <div className="col-sm-9" style={{ height: '40px', padding: '10px 16px' }}>
          {translate('eventLocationValuesCustomerOffice')}
        </div>
      </div>
      {
        map(FIELDS, field => (

          <div
            key={`input-${field}`}
            className="form-group row"
          >
            <label className="col-sm-3 form-control-label">
              {translate(`ordersShowFormsCustomerFields${uppercamelcase(field)}`)}
            </label>
            <div className="col-sm-9">
              {
                (field === ADDITIONAL_INSTRUCTIONS) ?
                  <textarea
                    name="address[additional_instructions]"
                    className="form-control"
                    rows="3"
                    defaultValue={additionalInstructions}
                  /> :
                  <input
                    type="text"
                    name={`address[${field}]`}
                    className="form-control"
                    defaultValue={address.get(field)}
                  />
              }
            </div>
          </div>
        ))
      }
    </div>
  </div>
);

InOfficeActivity.propTypes = {
  address: object,
  additionalInstructions: string,
  translate: func.isRequired,
};

export default withI18n(InOfficeActivity);
