import React from 'react';
import { string, bool } from 'prop-types';
import { blankish } from 'libs/support/string';
import renderHTML from 'react-render-html';

const OrderBasketOrderVariationDescription = ({
  className,
  isCafeOrdering,
  description,
  displayDescription,
}) => {
  if (blankish(description) || !displayDescription) return null;

  return (
    <tr className={`order-variation-description ${className}`}>
      { isCafeOrdering && <td /> }
      <td colSpan="2">
        {renderHTML(description)}
      </td>
      <td colSpan={3} />
    </tr>
  );
};

OrderBasketOrderVariationDescription.propTypes = {
  className: string.isRequired,
  isCafeOrdering: bool.isRequired,
  description: string,
  displayDescription: bool,
};

export default OrderBasketOrderVariationDescription;
