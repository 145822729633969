import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import renderHTML from 'react-render-html';

import BaseComponent from 'libs/components/BaseComponent';
import { blankish } from 'libs/support/string';
import { defaultMessages } from 'libs/i18n/default';

class OrderBasketOrderVariationInstructions extends BaseComponent {
  static propTypes = {
    className: PropTypes.string.isRequired,
    isCafeOrdering: PropTypes.bool.isRequired,
    value: PropTypes.string,
    intl: intlShape.isRequired,
  };

  render() {
    const { className, isCafeOrdering, value, intl } = this.props;
    const { formatMessage } = intl;

    if (blankish(value)) return null;

    return (
      <tr className={`order-variation-additional-instructions ${className}`}>
        { isCafeOrdering && <td className="no-borders p-b-15" /> }
        <td className="no-borders p-b-15" colSpan="5">
          {formatMessage(defaultMessages.ordersShowFormsOrderVariationsTermsAdditionalInstructions)}:
          <br />
          {renderHTML(value)}
        </td>
      </tr>
    );
  }
}

export default injectIntl(OrderBasketOrderVariationInstructions);
